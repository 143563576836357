import React, { PureComponent } from "react"
import {
  Container, Row
} from 'reactstrap'
import Header from "../../common/Header";
import Categories from "../App/components/Categories";
import TokenValidity from '../../helper/TokenValidity'
import { baseurl } from '../../constants/declaration'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { setDestinationCategoryProperties, setSurveyCompleted } from '../../actions/index'

function mapDispatchToProps(dispatch) {
  return {
    setDestinationCategoryProperties: destinationCategoryProperty => dispatch(setDestinationCategoryProperties(destinationCategoryProperty)),
    setSurveyCompleted: isSurveyComplete => dispatch(setSurveyCompleted(isSurveyComplete))
  }
}

let configToken = {}

class CateogorySelection extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      navigateTo: false, selectedOption: this.props.selectedDestinationCategoryId,
      selectedDestinationCategoryName: '',
      isSignOff: this.props.isSignOff.signOffFlag,
      category: this.props.category,
      answerSubCategory: this.props.answerSubCategory
    }
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidMount() {
    if (this.props.finalBudget && this.props.finalBudget.destinationCategoryId) {
      this.setState({ selectedOption: this.props.finalBudget.destinationCategoryId });
    }
    if (this.state.category) {
      let subCat = this.state.category[this.state.category.length - 1].subCategories;
      let ansCat = this.props.answerSubCategory;
      const lastSubCategoryId = subCat[subCat.length - 1].subCategoryId;
      for (let key in ansCat) {
        if (key === lastSubCategoryId) {
          this.props.setSurveyCompleted({ isSurveyComplete: true });
        }
      }

    }
  }

  handleOnChange(e) {
    this.setState({ selectedOption: e.target.value, selectedDestinationCategoryName: e.currentTarget.attributes.catname.value });
  }
  calculate = async () => {
    configToken = this.props.token
    let categories = this.props.destinationCategories[0];
    let categoryId = Number(this.state.selectedOption);
    let destinationCategoryProperty = await TokenValidity.checkTokenValidity(baseurl + '/SurveyCalculator/destination/' + categoryId + '/property', {}, configToken)
    destinationCategoryProperty.selectedDestinationCategoryName = categories[this.state.selectedOption - 1].destinationCategoryName;

    if (destinationCategoryProperty) {
      if (destinationCategoryProperty.destinationCategoryProperties.totalSquareFit === 0 || destinationCategoryProperty.destinationCategoryProperties.totalPropertyValue === 0) {
        destinationCategoryProperty.isNewCalculator = true
      } else {
        destinationCategoryProperty.isNewCalculator = false
      }

      this.props.setDestinationCategoryProperties(destinationCategoryProperty)
      this.setState({ navigateTo: true });
    }
  }
  renderCategories = () => {
    if (this.state.navigateTo) {
      return <Redirect to={{ pathname: '/calculator' }} />
    }
    var radioList = [];
    this.props.destinationCategories[0].map((value, i) => {

      radioList.push(
        <div className="col-lg-4" key={value.destinationCategoryName}>
          <label className="radioLabel" >
            <input
              type="radio"
              name="destCat"
              catname={value.destinationCategoryName}
              value={value.destinationCategoryId}
              onChange={this.handleOnChange}
              checked={parseInt(this.state.selectedOption) === parseInt(value.destinationCategoryId)}
              disabled={this.state.isSignOff}
            />
            {value.destinationCategoryName}
          </label>
        </div>)
        return true
    })
    return radioList;
  }
  render() {
    return (
      <>
        <Header />
        <Container fluid className="h-100 w-100">
          <Row className="app-content" >
            <Categories />
            <div className="col-lg-10">
              <div className="shadow h-100 question-block bg-white overflow-auto">
                <div className="p-5 h-100" style={{ maxHeight: "100vh" }}>
                  <div className="d-flex flex-column h-100" style={{ alignItems: "normal", justifyContent: "space-around" }}>
                    <h5 className="h5 text-center font-weight-bold question-heading mb-3">What destination categories you choose ?</h5>
                    <Row>
                      {this.renderCategories()}
                    </Row>
                    <div>
                      <p className="text-center">Move on to choose destination<br />and calculate budget.</p>
                      <button onClick={this.calculate} className="btn btn-primary" style={{ width: "max-content", margin: "0 auto", display: "block" }}> CALCULATE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

function mapStateToProps(state) {
  return { token: state.token, destinationCategories: state.destinationCategories, selectedDestinationCategoryId: state.selectedDestinationCategoryId.selectedDestinationCategoryId, finalBudget: state.finalBudget, isSignOff: state.isSignOff, category: state.category, answerSubCategory: state.answerSubCategory };
}

export default connect(mapStateToProps, mapDispatchToProps)(CateogorySelection)
