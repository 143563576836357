import React, { PureComponent } from "react"
import Food from "./Food";

class Result extends PureComponent {

    render() {
        return (
            <>
                <div className="shadow h-100 question-block bg-white overflow-auto">
                    <div className="p-5 h-100" style={{ maxHeight: '100vh' }}>
                        <div className="d-flex flex-column h-100">
                            <div className="text-right position-sticky bg-white p-4" style={{ top: '0px' }}>
                            </div>
                            <Food categoryId={this.props.categoryId} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Result
