import React, { PureComponent } from "react"
import {
    Label, Card, CardTitle, CardBody, Input
} from 'reactstrap'
import TokenValidity from '../../../helper/TokenValidity'
import { connect } from 'react-redux'
import { baseurl } from '../../../constants/declaration'
import { setSignOffFlag } from '../../../actions/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function mapDispatchToProps(dispatch) {
    return {
        setSignOffFlag: isSignOff => dispatch(setSignOffFlag(isSignOff)),
    }
}
class SurveySignOff extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { name: this.props.isSignOff.signOffBy, currentDate: this.props.isSignOff.signOffDate, active: false, disableCheck: this.props.isSignOff.signOffFlag, nameDisable: this.props.isSignOff.signOffFlag }
    }
    componentDidMount() {
        if (!this.state.currentDate) {
            var date = new Date();
            var formattedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
            this.setState({ currentDate: formattedDate })
        }
        if (!this.state.name) {
            this.setState({ name: '' })
        }
    }

    onChange(e) {
        this.setState({ name: e.target.value })
    }
    
    signOff = async () => {
        var date = new Date();

        let signedOff = await TokenValidity.checkTokenValidity(baseurl + '/Survey/signoff', { "signOffBy": this.state.name, "signOffDate": date }, this.props.token)
        if (signedOff.isSucceed) {
            this.props.setSignOffFlag({ signOffFlag: true, signOffBy: signedOff.signOff.signOffBy });
            toast('You have successfully singed off.', signedOff)
            this.setState({ active: true, disableCheck: true, nameDisable: true });
        }
        else {
            this.props.setSignOffFlag({ signOffFlag: false });
            toast(signedOff.message)
        }
    }
    
    onCheck(e) {
        this.setState({ active: e.target.checked })
    }

    render() {
        return (
            <div className="signOffContainer">
                <Card>
                    <CardTitle>Survey Sign Off</CardTitle>
                    <CardBody>
                        <label>Date: </label>  {this.state.currentDate}

                        <Input type="text" disabled={this.state.nameDisable} name="name" onChange={e => this.onChange(e)} value={this.props.isSignOff.signOffBy === null ? undefined : this.props.isSignOff.signOffBy} id="exampleEmail" placeholder="Enter Your Name" />
                        <Label check >
                            <Input type="checkbox" disabled={this.state.disableCheck} checked={this.state.active ? true : this.state.disableCheck} onChange={e => this.onCheck(e)} /> I have received my customized PDF that is based on my inputs into the Decision Tree Tool, and I will work with my manager and team to implement it.
                        </Label>
                        <div className="btn-container">
                            <p>Enter details and Sign Off</p>
                            <button className="btn btn-primary" onClick={this.signOff} disabled={!this.state.active || this.state.name === ''}>Sign Off</button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { token: state.token, isSignOff: state.isSignOff };
}

const SurveySignOffPage = connect(mapStateToProps, mapDispatchToProps)(SurveySignOff)

export default SurveySignOffPage
