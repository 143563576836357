import React, { PureComponent } from "react"
import {
  Row, Col, Card, CardHeader, CardBody, Progress
} from 'reactstrap';
import { connect } from 'react-redux'
import _ from 'lodash'

class PriorityBar extends PureComponent {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  renderBar = (prirorValue) => {
    let priority_arr = []
    prirorValue.map((value, index) => {
      if (parseInt(value.value) === -1) {
        priority_arr.push(
          <Row key={index} className="mb-2 mx-1">
            <Col className="bar-disabled-text p-0">{value['name']}</Col>
            <Col xs="8" className="align-self-center pr-0"><Progress className="progress-red bar-disabled" value={0} /></Col>
          </Row>
        )
      } else {
        priority_arr.push(
          <Row key={index} className="mb-2 mx-1">
            <Col className="p-0">{value['name']}</Col>
            <Col xs="8" className="align-self-center pr-0"><Progress className="progress-red" value={value['value']} /></Col>
          </Row>
        )
      }
      return true
    })
    return priority_arr
  }

  renderStaticPrior = () => {
    const static_priority_arr = []
    const dummyPrior = ['Short-Term', 'Mid-Term', 'Long-Term']
    dummyPrior.map((value, index) => {
      static_priority_arr.push(
        <Row key={index} className="mb-2 mx-1">
          <Col className="px-0">{value}</Col>
          <Col xs="8" className="align-self-center pr-0"><Progress className="progress-red" value="0" /></Col>
        </Row>
      )
      return true
    })

    return static_priority_arr
  }

  renderPriorityBar = () => {
    const { recomPriorBar, currentId, category } = this.props
    let priority_arr = []

    if (recomPriorBar) {
      let subCategoryId = currentId['currntSubCategoryId']
      let categoryId = currentId['currntCategoryId']

      if (subCategoryId === 0) {
        priority_arr = this.renderStaticPrior()
      } else {
        if (recomPriorBar[subCategoryId]) {

          const { prioritization } = recomPriorBar[subCategoryId]
          priority_arr = this.renderBar(prioritization)

        } else if (category) {
          if (category.length > 0) {
            let categoryObj = _.find(category, { categoryId: categoryId })
            const { subCategories } = categoryObj
            let subCategoryObj = _.find(subCategories, { subCategoryId: subCategoryId })
            const { prioritizations } = subCategoryObj
            priority_arr = this.renderBar(prioritizations)
          }
        } else {
          priority_arr = this.renderStaticPrior()
        }
      }
    } else {
      priority_arr = this.renderStaticPrior()
    }

    return priority_arr
  }

  render() {
    return (
      <>
        <Card className="border-0 rounded-0 mb-3 prioritization">
          <CardHeader className="font-weight-bold text-uppercase text-white py-2">Prioritization</CardHeader>
          <CardBody className="p-2">
            {this.renderPriorityBar()}
          </CardBody>
        </Card>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { recomPriorBar, currentId, category } = state
  return { recomPriorBar, currentId, category }
}

const Prioritization = connect(mapStateToProps)(PriorityBar)
export default Prioritization
