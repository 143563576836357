import React, { PureComponent } from "react"
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { addCategoryResult, setSurveyCompleted, setUnansweredQuestion } from '../../../../../actions/index'
import { baseurl } from '../../../../../constants/declaration'
import TokenValidity from '../../../../../helper/TokenValidity'
import { Redirect } from 'react-router'
import _ from 'lodash'

function mapDispatchToProps(dispatch) {
  return {
    addCategoryResult: result => dispatch(addCategoryResult(result)),
    setSurveyCompleted: isSurveyComplete => dispatch(setSurveyCompleted(isSurveyComplete)),
    setUnansweredQuestion: unAnswerQuestions => dispatch(setUnansweredQuestion(unAnswerQuestions)),
  }
}

class SubmitAnswers extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      toFoodResult: false,
      toSpaceResult: false,
      toExpResult: false,
      showWarnings: []
    };
  }

  toResult = async () => {
    const { categoryId } = this.props
    let payload = {}
    const { token } = this.props
    const answerResponse = await TokenValidity.checkTokenValidity(baseurl + '/Survey/result/survey/category/' + categoryId, payload, token)
    if (answerResponse) {
      let resultObj = {
        [categoryId]: answerResponse
      }
      this.props.addCategoryResult(resultObj)
      this.setState({ toFoodResult: true })
    } else {
      this.setState({ toFoodResult: false })
    }
  }

  enableWarning = () => {
    const { categoryAnswer, subCategoryId, questionsList } = this.props
    let warningArr = []
    Object.keys(categoryAnswer).map((allSubId, id) => {
      if (allSubId <= parseInt(subCategoryId)) {

        let questionList = questionsList[id]['questions']
        questionList.map((valArr) => {
          if (valArr.isActive && valArr.questionType.questionTypeName !== 'InputBox') {
            let Answer = categoryAnswer[allSubId][valArr.questionId]
            if (Answer) {
              if (Answer.length <= 0) {
                warningArr = this.createWarningArr(allSubId, questionList, valArr.questionId, warningArr)
              } else {
                Object.values(Answer).map((answer) => {
                  if (answer.value === '' || answer.value === null) {
                    warningArr = this.createWarningArr(allSubId, questionList, valArr.questionId, warningArr)
                  }
                  return true
                })
              }
            }
            else {
              warningArr = this.createWarningArr(allSubId, questionList, valArr.questionId, warningArr)
            }
          }
          return true
        })
      }
      return true
    })
    return warningArr
  }

  createWarningArr = (allSubId, questionList, quesId, warningArr) => {
    const { questionsList } = this.props
    let subCatName = _.find(questionsList, { subCategoryId: parseInt(allSubId) })
    let qid = _.findIndex(questionList, { questionId: quesId })
    warningArr.push({ 'name': subCatName.subCategoryName, 'questionId': qid + 1 })
    return warningArr
  }

  findQuesIdByDisplayOrder = () => {
    const { questionsList, subCategoryId, currentQuestionId } = this.props
    let questionObj = _.find(questionsList, { subCategoryId: subCategoryId })
    
    let question = _.find(questionObj['questions'], { displayOrder: currentQuestionId })
    const { questionId } = question
    if (question.questionType.questionTypeName !== 'InputBox') {
      return questionId
    } else {
      const { questions } = questionObj
      let indexToFind = questions.length - 3
      return questions[indexToFind].questionId
    }
  }

  checkEnableDisable = () => {
    let warningArr = []
    let qId = null
    this.props.setSurveyCompleted({ isSurveyComplete: true });
    const { categoryAnswer, subCategoryId, currentQuestionId, lastQuesId, answerSubCategory } = this.props
    Object.keys(answerSubCategory).map((key) => {
      if (answerSubCategory[key] === false) {
        this.props.setSurveyCompleted({ isSurveyComplete: false });
      }
      return true
    })
    let disableSubmit = true
    let categoryStatus = true

    if (lastQuesId && lastQuesId.id === currentQuestionId) {
      if (categoryAnswer) {
        if (categoryAnswer[subCategoryId]) {
          qId = this.findQuesIdByDisplayOrder()
          if (categoryAnswer[subCategoryId][qId]) {
            let tempAnswerObj = { ...categoryAnswer[subCategoryId][qId] }
            if (tempAnswerObj[0]) {
              Object.keys(answerSubCategory).map((subActive) => {
                if (parseInt(subActive) <= parseInt(subCategoryId) && answerSubCategory[subActive] && categoryStatus) {
                  categoryStatus = true
                  disableSubmit = false
                } else if (parseInt(subActive) <= parseInt(subCategoryId) && !answerSubCategory.subActive) {
                  categoryStatus = false
                  warningArr = this.enableWarning()
                  disableSubmit = true
                }
                return true
              })
            } else {
              warningArr = this.enableWarning()
              disableSubmit = true
            }
          }
        }
      }
    } else if (lastQuesId && lastQuesId.id === currentQuestionId && lastQuesId.qType === "InputBox") {
      disableSubmit = false
    } else {
      disableSubmit = true
    }
    this.props.setUnansweredQuestion(warningArr)
    return (
      <>
        <Button color="primary" block className="m-auto w-75" disabled={disableSubmit} onClick={this.toResult}>SUBMIT</Button>
      </>
    )
  }

  render() {
    if (this.state.toFoodResult) {
      return <Redirect to={{ pathname: '/categoryResult', state: { categoryId: this.props.categoryId, subCategoryId: this.props.subCategoryId } }} />
    }

    return (
      <>
        <div className="d-flex h-100 mb-2">
          <div className="text-center">
            <p className="mb-3">
              Answer all questions of each category and submit to see your recommendations.
            </p>
            {this.checkEnableDisable()}
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { category, token, lastQuesId, categoryAnswer, answerSubCategory, questionsList } = state
  return {
    category,
    token,
    lastQuesId,
    categoryAnswer,
    answerSubCategory,
    questionsList
  }
}


const SubmitAnswer = connect(mapStateToProps, mapDispatchToProps)(SubmitAnswers)
export default SubmitAnswer
