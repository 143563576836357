import React, { PureComponent, Fragment } from "react"
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class AnswerBreadcrumb extends PureComponent {

  renderOptions = () => {
    const { categoryId, subCategoryId, category, categoryAnswer } = this.props
    let categories_arr = _.find(category, { categoryId: parseInt(categoryId) })
    let subCategories = categories_arr['subCategories']
    let breadCrumbArr = []
    let activeFlag = false

    subCategories.map((value, index) => {

      if (Object.keys(categoryAnswer[value['subCategoryId']]).length > 0) {
        activeFlag = true
      }
      else {
        activeFlag = false
      }

      breadCrumbArr.push(
        <Fragment key={index}>
          {activeFlag && <BreadcrumbItem key={index} active={value['subCategoryId'] === subCategoryId ? true : false} className={value['subCategoryId'] > subCategoryId ? "upcoming" : ""}>
            <Link key={index} to={{ pathname: '/dashboard', state: { disable: false, categoryId: categoryId, subCategoryId: value['subCategoryId'], questionId: value['questions'][0]['displayOrder'], disableSubmitButton: true } }}>
              {value['subCategoryName']}</Link>
          </BreadcrumbItem>
          }
          {!activeFlag &&
            <BreadcrumbItem key={index} active={value['subCategoryId'] === subCategoryId ? true : false} className={value['subCategoryId'] > subCategoryId ? "upcoming" : ""}>{value['subCategoryName']}</BreadcrumbItem>
          }
        </Fragment>
      )
      return true
    })

    return breadCrumbArr
  }

  render() {
    return (
      <Breadcrumb listClassName="bg-transparent mb-0">
        <BreadcrumbItem key={100} className="font-weight-bold breadcrumb-title">Answers ></BreadcrumbItem>
        {this.renderOptions()}
      </Breadcrumb>
    )
  }
}

function mapStateToProps(state) {
  const { category, categoryAnswer } = state
  return {
    category, categoryAnswer
  }
}

const AnswersBreadcrumb = connect(mapStateToProps)(AnswerBreadcrumb)
export default AnswersBreadcrumb
