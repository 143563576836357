export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_QUESTIONS = "ADD_QUESTIONS"
export const ADD_STUDENT_COUNT = "ADD_STUDENT_COUNT"
export const ADD_HALL_SIZE = "ADD_HALL_SIZE"
export const ADD_ANSWER_CHIPS = "ADD_ANSWER_CHIPS"
export const ADD_TOKEN = "ADD_TOKEN"
export const LOG_OUT = "LOG_OUT"
export const ADD_CURRENT_ID = "ADD_CURRENT_ID"
export const ADD_SURVEY_ID = "ADD_SURVEY_ID"
export const STORE_GENERAL_INFO = "STORE_GENERAL_INFO"
export const ADD_RECOM_PRIOR = "ADD_RECOM_PRIOR"
export const SET_DESTINATION_CATEGORIES = "SET_DESTINATION_CATEGORIES"
export const SET_DESTINATION_CATEGORY_PROPERTIES = "SET_DESTINATION_CATEGORY_PROPERTIES"
export const SET_FINAL_BUDGET = "SET_FINAL_BUDGET"
export const ADD_RESULT = "ADD_RESULT"
export const ADD_LAST_ID = "ADD_LAST_ID"
export const ADD_SUB_CATEGORY_ID = "ADD_SUB_CATEGORY_ID"
export const SET_SURVEY_RESULT = "SET_SURVEY_RESULT"
export const ADD_SIGNOFF_FLAG = "ADD_SIGNOFF_FLAG"
export const SET_SELECTED_DESTINATION_CATID = "SET_SELECTED_DESTINATION_CATID"
export const SET_SURVEY_COMPLETED = "SET_SURVEY_COMPLETED"
export const UNANSWERED_QUESTION = "UNANSWERED_QUESTION"