import React, { PureComponent } from "react"
import { Badge } from 'reactstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

class AnswersChip extends PureComponent {

  state = {
    questionId: null
  }

  renderChips = () => {
    const { categoryAnswer, subCategoryId, currentId, questionsList } = this.props
    let catId = ''
    if (currentId) {
      catId = currentId.currntCategoryId
    }
    let badge_arr = []
    let icon = ''
    let badge_val = null

    let questionArr = _.find(questionsList, { subCategoryId: subCategoryId })

    if (categoryAnswer) {
      if (categoryAnswer[subCategoryId]) {
        let tempAnsObj = { ...categoryAnswer[subCategoryId] }

        Object.keys(tempAnsObj).map((keyTemp, id) => {
          badge_val = tempAnsObj[keyTemp]
          let question = _.find(questionArr.questions, { subCategoryId: subCategoryId, questionId: parseInt(keyTemp) })
          if (question.isActive) {
            let temp_badge = []
            if (badge_val) {
              Object.keys(badge_val).map((key, index) => {
                if (badge_val[key]['name'] !== 'Others' && badge_val[key]['name'] !== '') {
                  let keysSorted = badge_val.sort(function (a, b) { return a['name'] - b['name'] })
                  let sorted_arr = []
                  icon = '>'
                  Object.keys(keysSorted).map((val) => {
                    sorted_arr.push(keysSorted[val]['value'])
                    return true
                  })
                  temp_badge = [...sorted_arr]
                } else {
                  icon = ','
                  temp_badge.push(badge_val[key]['value'])
                }
                return true
              })

              if (temp_badge.length > 1) {
                temp_badge = temp_badge.join(icon)
              }

              badge_arr[question.displayOrder] = 
                <Link key={id} to={{ pathname: '/dashboard', state: { disable: false, categoryId: catId, subCategoryId: subCategoryId, questionId: parseInt(question.displayOrder), disableSubmitButton: true } }}>
                  <Badge value={question.displayOrder} pill className="py-2 px-3 mt-2 mr-1 pointer text-dark text-left">{temp_badge}</Badge>
                </Link>
              
            } else {
              badge_arr.push(
                <> </>
              )
            }

          } else {
            badge_arr.push(

            )
          }
          return true
        })
      }
    }
    
    return badge_arr
  }

  render() {
    return (
      <>
        <div className="px-3 py-1 chip-block">
          {this.renderChips()}
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { categoryAnswer, currentId, questionsList } = state

  return {
    categoryAnswer, currentId, questionsList
  }
}

const AnswersChips = connect(mapStateToProps)(AnswersChip)
export default AnswersChips
