import React, { PureComponent } from "react"
import { Row, Col, Button, FormGroup, Label, Card, CardBody } from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { addQuestions, addStudentCount, addSurveyId, addCurrentId, addGeneralAccountInfo, setUnansweredQuestion } from '../../actions/index'
import TokenValidity from '../../helper/TokenValidity'
import { baseurl } from '../../constants/declaration'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

function mapDispatchToProps(dispatch) {
  return {
    addQuestions: questionsList => dispatch(addQuestions(questionsList)),
    addStudentCount: studentCount => dispatch(addStudentCount(studentCount)),
    addSurveyId: surveyGUID => dispatch(addSurveyId(surveyGUID)),
    addCurrentId: currentId => dispatch(addCurrentId(currentId)),
    addGeneralAccountInfo: gnrlAccountInfo => dispatch(addGeneralAccountInfo(gnrlAccountInfo)),
    setUnansweredQuestion: unAnsweredQuestion => dispatch(setUnansweredQuestion(unAnsweredQuestion))
  }
}

class AccountInfo extends PureComponent {

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ locale: 'en' });
    this.state = {
      unitNumberMessage: '',
      name: '',
      unit: '',
      dinningHall: '',
      generalMnger: '',
      districtMnger: '',
      svpName: '',
      studentCount: '',
      emailId: '',
      contactNumb: '',
      toQuestion: false,
      categryId: null,
      subCatgryId: null,
      displayOrder:null
    }
  }

  componentDidMount() {
    const { schoolName, unitNumber, diningHallName, totalStudent, email, contacts } = this.props.gnrlAccountInfo
    this.props.addCurrentId({ currntCategoryId: 1, currntSubCategoryId: 1 })
    this.setState({ name: schoolName, unit: unitNumber, dinningHall: diningHallName, studentCount: totalStudent, emailId: email, contacts: contacts })
  }

  fetchQuestion() {
    const { category } = this.props

    const subCategoryId = category[0]['subCategories'][0]['subCategoryId']
    const categoryId = category[0]['categoryId']
    const dispOrder = category[0]['subCategories'][0]['questions'][0]['displayOrder']
    
    let tempQuestionObj = []
    let tempQuestionArr = []

    category.map((value) => {
      value['subCategories'].map((subValue) => {
        tempQuestionObj = {
          'categoryId': value['categoryId'],
          'subCategoryId': subValue['subCategoryId'],
          'subCategoryName': subValue['subCategoryName'],
          'questions': subValue['questions']
        }
        tempQuestionArr.push(tempQuestionObj)
        return true
      })
      return true
    })

    if (this.props.questionsList) {
      if (this.props.questionsList.length === 0) {
        this.props.addQuestions(tempQuestionArr)
      }
    } else {
      this.props.addQuestions(tempQuestionArr)
    }

    this.setState({ toQuestion: true, categryId: categoryId, subCatgryId: subCategoryId, displayOrder:dispOrder })
  }

  submitGeneralAccountInfo = async (values) => {
    const { token } = this.props
    this.props.addGeneralAccountInfo(values)
    const gnrlAccountObjct = await TokenValidity.checkTokenValidity(baseurl + '/Survey/start', values, token)

    const { isSucceed } = gnrlAccountObjct
    if (isSucceed) {
      this.props.addSurveyId({ surveyId: gnrlAccountObjct['surveyId'] })
      this.fetchQuestion()
    } else {
      const { errors } = gnrlAccountObjct
      Object.keys(errors).map((val) => {
        this.validator.showMessageFor(val)
        this.forceUpdate();
        return true
      })
    }
  }


  setStudentCount = (e) => {
    this.setState({ studentCount: e.target.value })
  }

  handleUnit = (e) => {
    let unitValue = parseInt(e.target.value)
    if (Number.isInteger(unitValue)) {
      if (unitValue.toString().length >= 5) {
        this.setState({ unitNumberMessage: "Unit number length cannot be more then 4.", unit: unitValue })
      } else {
        this.setState({ unitNumberMessage: "", unit: unitValue })
      }
    } else {
      this.setState({ unit: '' })
      if (unitValue) {
        this.setState({ unitNumberMessage: "Unit number accept only integer.", unit: '' })
      }
    }
  }

  addContacts = (arrayHelpers) => {
    if (arrayHelpers.form.values) {
      let tempEmail = arrayHelpers.form.values.contacts
      let length = tempEmail.length
      if (tempEmail[length - 1]) {
        if (tempEmail[length - 1].designation !== '' && tempEmail[length - 1].firstName !== '' && tempEmail[length - 1].lastName !== '') {

          arrayHelpers.push({ designation: '', firstName: '', lastName: '', contactNumber: undefined, email: '', id: '' })
        }
      }
    }
  }

  render() {
    let initialValue = {}
    const { toQuestion, studentCount } = this.state

    if (this.props.gnrlAccountInfo.contacts) {
      initialValue = { schoolName: this.props.gnrlAccountInfo.schoolName, unitNumber: this.props.gnrlAccountInfo.unitNumber, diningHallName: this.props.gnrlAccountInfo.diningHallName, totalStudent: this.props.gnrlAccountInfo.totalStudent, contacts: this.props.gnrlAccountInfo.contacts }
    } else {
      initialValue = { schoolName: '', unitNumber: '', diningHallName: '', totalStudent: '', contacts: [{ designation: '', firstName: '', lastName: '', contactNumber: '', email: '', id: '' }] }
    }

    const { isSignOff } = this.props
    this.props.addStudentCount({ studentCount: studentCount })

    if (toQuestion) {
      const { categryId, subCatgryId, displayOrder } = this.state
      return <Redirect to={{ pathname: '/dashboard', state: { disable: false, categoryId: categryId, subCategoryId: subCatgryId, questionId: displayOrder, disableSubmitButton: true, recom: [], prior: [] } }} />
    }
    return (
      <div className="shadow h-175 question-block bg-white overflow-auto">
        <div className="p-5 h-100" style={{ maxHeight: '150vh' }}>
          <h6 className="h6 font-weight-bold d-block border-bottom border-primary pb-2">GENERAL ACCOUNT INFO</h6>
          <Formik
            initialValues={initialValue}
            validationSchema={Yup.object().shape({
              contacts: Yup.array()
                .of(
                  Yup.object().shape({
                    designation: Yup.string()
                      .trim()
                      .matches(/^[a-zA-Z ]*$/, 'Only accept alphabets', { message: 'Phone number is invalid.', excludeEmptyString: true })
                      .required('Required'),
                    firstName: Yup.string()
                      .matches(/^[A-Za-z]+$/, 'Only accept alphabets')
                      .required('Required'),
                    lastName: Yup.string()
                      .matches(/^[A-Za-z]+$/, 'Only accept alphabets')
                      .required('Required'),
                    email: Yup.string()
                      .email('Invalid email')
                      .required('Required'),
                    contactNumber: Yup.string()
                      .trim()
                      .min(7, 'Contact number cannot be less than 7 digits') // eslint-disable-next-line
                      .matches(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/, { message: 'Phone number is invalid.' })
                      .max(20, 'Contact number cannot be greater than 20 digits')
                      .required('Required'),
                  })
                ),
              schoolName: Yup.string()
                .required('Required'),
              unitNumber: Yup.string()
                .max(250, 'Unit numbers length cannot be greater than 250 characters')
                .required('Required').typeError("Unit number must be numeric"),
              diningHallName: Yup.string()
                .required('Required'),
              totalStudent: Yup.string()
                .required('Required')
            })}
            onSubmit={values => this.submitGeneralAccountInfo(values)}
            render={({ values, setFieldValue }) => (
              <Form>
                <FormGroup row>
                  <Label sm={5} for="q1">Name of your School / College</Label>
                  <Col sm={7}>
                    <Field type="text" className="form-control" autoComplete="off" name="schoolName" id="q1" disabled={isSignOff['signOffFlag']} placeholder="Enter the name of your School / College" />
                    <p className="text-danger m-0 small"> <ErrorMessage name="schoolName" className="invalid-feedback" /></p>

                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={5} for="unitNumber">What is your unit number?</Label>
                  <Col sm={7}>
                    <Field type="text" className="form-control" autoComplete="off" name="unitNumber" id="unitNumber" disabled={isSignOff['signOffFlag']} placeholder="Enter your unit number" />
                    <p className="text-danger m-0 small"> <ErrorMessage name="unitNumber" className="invalid-feedback" /></p>

                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={5} for="q3">What is the name of the dining hall?</Label>
                  <Col sm={7}>
                    <Field type="text" className="form-control" autoComplete="off" name="diningHallName" id="q3" disabled={isSignOff['signOffFlag']} placeholder="Enter name of the dining hall" />
                    <p className="text-danger m-0 small"> <ErrorMessage name="diningHallName" className="invalid-feedback" /></p>

                  </Col>
                </FormGroup>
                <Row>
                  <Label sm={5} for="q7">What is the total number of students on campus?</Label>
                  <Col sm={7}>
                    <Field component="select" className="form-control" style={{ height: "44px" }} name="totalStudent" id="q7" disabled={isSignOff['signOffFlag']} onChange={e => {
                      this.setState({ studentCount: e.target.value });
                      setFieldValue('totalStudent', e.target.value);
                    }}>
                      <option value="" disabled>Enter total number of students</option>
                      <option value="0-1000">0-1000</option>
                      <option value="1001-3000">1001-3000</option>
                      <option value="3001+">3001+</option>
                    </Field>
                    <p className="text-danger m-0 small"> <ErrorMessage name="totalStudent" className="invalid-feedback" /></p>

                  </Col>
                </Row>
                <Card className="mt-4 pb-2">
                  <CardBody className="py-0">
                    <h6 className="bg-white d-inline-block px-2 position-relative" style={{ top: "-10px" }}>Contacts</h6>
                    <Row className="mb-0">
                      <Col className="px-1">
                        <Label className="mb-0">  First Name</Label>
                      </Col>
                      <Col className="px-1">
                        <Label className="mb-0">    Last Name</Label>
                      </Col>

                      <Col className="px-1">
                        <Label className="mb-0">  Title</Label>
                      </Col>
                      <Col className="px-1">
                        <Label className="mb-0">  Email Address</Label>
                      </Col>
                      <Col className="px-1">
                        <Label className="mb-0">  Contact Number</Label>
                      </Col>
                      <Col className="px-1" sm={1}>

                        <Button color="link"></Button>
                      </Col>
                    </Row>
                    <FieldArray
                      name="contacts"
                      render={arrayHelpers => (
                        <div>
                          {values.contacts.map((friend, index) => (

                            <FormGroup key={index} row className="my-0 pt-2">

                              <Col className="px-1">
                                <Field className="form-control" disabled={isSignOff['signOffFlag']} name={`contacts[${index}].firstName`} id="fname" placeholder="First Name" />
                                <p className="text-danger m-0 small"> <ErrorMessage name={`contacts[${index}].firstName`} className="invalid-feedback" /></p>

                              </Col>
                              <Col className="px-1">
                                <Field className="form-control" disabled={isSignOff['signOffFlag']} name={`contacts[${index}].lastName`} id="lname" placeholder="Last Name" />
                                <p className="text-danger m-0 small"> <ErrorMessage name={`contacts[${index}].lastName`} className="invalid-feedback" /></p>

                              </Col>
                              <Col className="px-1">
                                <Field className="form-control" disabled={isSignOff['signOffFlag']} name={`contacts[${index}].designation`} id="Designation" placeholder="Title" />
                                <p className="text-danger m-0 small"> <ErrorMessage name={`contacts[${index}].designation`} className="invalid-feedback" /></p>

                              </Col>
                              <Col className="px-1">
                                <Field className="form-control" disabled={isSignOff['signOffFlag']} name={`contacts[${index}].email`} id="email" placeholder="Email Address" />
                                <p className="text-danger m-0 small"> <ErrorMessage name={`contacts[${index}].email`} className="invalid-feedback" /></p>
                              </Col>
                              <Col className="px-1">
                                <Field className="form-control" disabled={isSignOff['signOffFlag']} name={`contacts[${index}].contactNumber`} id="contact" placeholder="Contact Number" />
                                <p className="text-danger m-0 small "> <ErrorMessage name={`contacts[${index}].contactNumber`} className="invalid-feedback" /></p>

                              </Col>
                              <Col className="px-1" xs={"1"}>

                                <Field type="hidden" value={index} name={`contacts[${index}].id`} />
                                {!isSignOff['signOffFlag'] && <Button color="link" className="delete-icon" disabled={values.contacts.length > 1 ? false : true} onClick={() => arrayHelpers.remove(index)}>
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                                }
                              </Col>
                            </FormGroup>

                          ))}

                          <Row>
                            <Col xs={12} className="text-right">
                              {!isSignOff['signOffFlag'] && <Button color="link" className="px-0" disabled={!(values.contacts[values.contacts.length - 1].firstName && values.contacts[values.contacts.length - 1].lastName && values.contacts[values.contacts.length - 1].designation)} onClick={() => this.addContacts(arrayHelpers)}>+ Add another contact</Button>}
                            </Col>
                          </Row>
                        </div>
                      )}
                    />
                  </CardBody>
                </Card>


                <Col className="text-right px-0 mt-2">
                  <Button type='submit' color="primary" className="mt-2 px-5" onClick={this.validate}>Start</Button>
                </Col>
              </Form>
            )}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { category, token, gnrlAccountInfo, questionsList, isSignOff } = state
  return { category, token, gnrlAccountInfo, questionsList, isSignOff }
}

const GeneralAccountInfo = connect(mapStateToProps, mapDispatchToProps)(AccountInfo)
export default GeneralAccountInfo
