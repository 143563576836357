import React, { PureComponent } from "react"
import { Table, Button, Row, Col, Modal } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import TokenValidity from '../../../../helper/TokenValidity'
import { baseurl } from '../../../../constants/declaration'
import { setSurveyResult, setSelectedDestinationCategoryId } from '../../../../actions/index'
import _ from 'lodash'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

function mapDispatchToProps(dispatch) {
    return {
        setSurveyResult: surveyResult => dispatch(setSurveyResult(surveyResult)),
        setSelectedDestinationCategoryId: selectedDestinationCategoryId => dispatch(setSelectedDestinationCategoryId(selectedDestinationCategoryId)),
    }
}

class ResultPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            toQuestion: false,
            newCategoryId: null,
            newSubCategoryId: null,
            newQuestionId: null,
            isLastCategory: false,
            finalSurveyResult: false,
            categorySelection: false,
            toCategorySelection: false,
            rmodal: false,
            pmodal: false
        }

        this.toggle = this.toggle.bind(this);
        this.ptoggle = this.ptoggle.bind(this);
    }

    toggle(urlPath) {
        this.setState(prevState => ({
            rmodal: !prevState.rmodal,
            url: urlPath
        }));
    }

    ptoggle(urlPath) {
        this.setState(prevState => ({
            pmodal: !prevState.pmodal,
            url: urlPath
        }));
    }

    componentDidMount() {
        const { category, categoryId } = this.props

        if (categoryId === category[category.length - 1].categoryId) {
            this.setState({ isLastCategory: true });
        }
    }

    renderCategoryResult = () => {
        const { result, categoryId } = this.props
        const { category } = result[categoryId]
        let result_arr = []

        category.results.map((value, index) => {
            const externalCloseBtn = <button className="close text-white" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
            result_arr.push(
                <tr key={index}>
                    <td className="align-middle text-left py-2"><FontAwesomeIcon icon={faCircle} size="xs" className="text-light-blue mr-2" /> {value['subCategoryName']} </td>
                    <td className="py-2"><span className="result-height__28 d-inline-block bg-theme-blue text-white w-75 m-auto p-1 font-weight-bold" >{value['recommendation']}</span> <FontAwesomeIcon icon={faInfoCircle} className="text-light-blue ml-2 pointer" onClick={() => this.toggle(value['recommendationLink'])} /></td>
                    <Modal centered size="xl" isOpen={this.state.rmodal} toggle={this.toggle} external={externalCloseBtn}>
                        <iframe title={index} src={this.state.url} height={600}/>
                    </Modal>
                    <td className="py-2"><span className="result-height__28 d-inline-block bg-theme-red text-white w-75 m-auto p-1 font-weight-bold">{value['prioritization']}</span></td>
                </tr>
            )
            return true
        })

        return result_arr
    }

    toNextCategory = async () => {
        const { category, categoryId } = this.props

        if (this.state.isLastCategory) {
            let surveyResult = await TokenValidity.checkTokenValidity(baseurl + '/Survey/result/survey', {}, this.props.token)
            this.props.setSurveyResult([surveyResult.categories]);
            this.setState({ finalSurveyResult: true });

        } else {
            let indexCat = _.findIndex(category, { 'categoryId': categoryId })
            let newCategoryId = category[indexCat + 1].categoryId
            let subCatObj = _.find(category, { 'categoryId': newCategoryId })
            const { subCategories } = subCatObj
            this.setState({ newCategoryId: newCategoryId, newSubCategoryId: subCategories[0].subCategoryId, newQuestionId: subCategories[0].questions[0].displayOrder, toQuestion: true })
        }
    }

    renderSurveyResult() {
        let surveyResults = this.props.surveyResult[0];
        var categoryList = [];
        surveyResults.map((value, i) => {
            categoryList.push(
                <div key={i}>
                    <Row>
                        <Col xs="auto">
                            <h3>Category: <span className="font-weight-bold text-uppercase">{value.categoryName}</span></h3>
                        </Col>
                        <Col>
                            <div className="my-3 border border-primary" />
                        </Col>
                    </Row>
                    <h6>According to your responses we suggest you:</h6>
                    <Table borderless className="text-center my-4">
                        <thead>
                            <tr className="shadow-sm bg-light">
                                <th className="align-middle text-left" width="33%">Sub-Category</th>
                                <th width="33%">Recommendation</th>
                                <th width="33%">Prioritization</th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.results.map((resultVal, index) => {
                                const externalCloseBtn = <button className="close text-white" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
                                return (
                                    <tr key={index}>
                                        <td className="align-middle text-left py-2"><FontAwesomeIcon icon={faCircle} size="xs" className="text-light-blue mr-2" /> {resultVal['subCategoryName']} </td>
                                        <td className="py-2"><span className="result-height__28 d-inline-block bg-theme-blue text-white w-75 m-auto p-1 font-weight-bold" >{resultVal['recommendation']}</span>  <FontAwesomeIcon icon={faInfoCircle} className="text-light-blue ml-2 pointer" onClick={() => this.toggle(resultVal['recommendationLink'])} /></td>
                                        <Modal centered size="xl" class isOpen={this.state.rmodal} toggle={this.toggle} external={externalCloseBtn}>
                                            <iframe title={index} src={this.state.url} height={600} />
                                        </Modal>
                                        <td className="py-2"><span className="result-height__28 d-inline-block bg-theme-red text-white w-75 m-auto p-1 font-weight-bold">{resultVal['prioritization']}</span></td>
                                    </tr>
                                )
                            }

                            )

                            }

                        </tbody>
                    </Table>

                </div>
            )
            return true
        });

        return categoryList;
    }
    navigateToCategorySelection = async () => {
        this.setState({ toCategorySelection: true });
    }
    render() {
        const { result, categoryId } = this.props
        const { newCategoryId, newSubCategoryId, newQuestionId, toQuestion } = this.state
        if (this.state.toCategorySelection) {
            return <Redirect to='/categoryselection' />;
        }


        if (toQuestion) {
            return <Redirect to={{ pathname: '/dashboard', state: { disable: false, categoryId: newCategoryId, subCategoryId: newSubCategoryId, questionId: newQuestionId, disableSubmitButton: true } }} />
        }
        if (this.state.finalSurveyResult) {

            return (<>
                {this.renderSurveyResult()}
                <p className="text-center"> Move on to full course calculator </p>
                <Button color="primary" size="lg" block className="mx-auto w-auto mb-5" onClick={e => this.navigateToCategorySelection(e)}>CALCULATOR</Button>
            </>
            )

        }
        return (
            <>
                <Row>
                    <Col xs="auto">
                        <h3>Category: <span className="font-weight-bold text-uppercase"> {result[categoryId].category.categoryName} </span></h3>
                    </Col>
                    <Col>
                        <div className="my-3 border border-primary" />
                    </Col>
                </Row>
                <h6>According to your responses we suggest you:</h6>
                <Table borderless className="text-center my-4">
                    <thead>
                        <tr className="shadow-sm bg-light">
                            <th className="align-middle text-left" width="33%">Sub-Category</th>
                            <th width="33%">Recommendation</th>
                            <th width="33%">Prioritization</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderCategoryResult()}
                    </tbody>
                </Table>

                {!this.state.isLastCategory ? <div className="m-auto  w-auto text-center"><span className="d-inline-block p-1 my-1">Move on to next category</span><Button color="primary" size="lg" block onClick={this.toNextCategory}>NEXT CATEGORY</Button></div> : <><p className="text-center">Move on to see results of all categories</p> <Button color="primary" size="lg" block className="mx-auto w-auto" onClick={this.toNextCategory}>RESULT</Button></>}
            </>
        )

    }
}

function mapStateToProps(state) {
    const { result, category, token, surveyResult, selectedDestinationCategoryId } = state
    return { result, category, token, surveyResult, selectedDestinationCategoryId }
}

const Food = connect(mapStateToProps, mapDispatchToProps)(ResultPage)
export default Food
