import React, { PureComponent } from "react"
import {
    Col, Card, CardHeader, CardBody
} from 'reactstrap';
  
class NoOfStudents extends PureComponent {

    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
      
  render() {
    const { studentCount } = this.props
    return (
        <>
            <Col className="pl-xl-2">
                <Card className="border-0 rounded-0 mb-3 no-of-students">
                    <CardHeader className="font-weight-bold text-uppercase text-white py-2">NO. OF STUDENTS</CardHeader>
                    <CardBody className="p-2">
                        {studentCount}
                    </CardBody>
                </Card>
            </Col>
        </>
    )
  }
}
export default NoOfStudents
