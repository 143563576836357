const fetchPOSTAPIHelper = async (url, payload, token) => {    
    return (fetch(url, {
        'method': 'POST',
        'body': JSON.stringify(payload),
        'Content-Type': 'application/json',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'bearer '+token['token'],
        },
    }).then(response => response.json())
        .then(data => {
            if (data) {
                return data
            } else {
                console.log('error at call API')
            }
        }));
}

export default (fetchPOSTAPIHelper);