import {
    ADD_CATEGORY, ADD_QUESTIONS, ADD_STUDENT_COUNT, ADD_HALL_SIZE, ADD_ANSWER_CHIPS, ADD_TOKEN, LOG_OUT, ADD_CURRENT_ID, ADD_SURVEY_ID,
    STORE_GENERAL_INFO, ADD_RECOM_PRIOR, SET_DESTINATION_CATEGORIES, SET_DESTINATION_CATEGORY_PROPERTIES, SET_FINAL_BUDGET, ADD_RESULT, ADD_LAST_ID, ADD_SUB_CATEGORY_ID, SET_SURVEY_RESULT, ADD_SIGNOFF_FLAG, SET_SELECTED_DESTINATION_CATID, SET_SURVEY_COMPLETED, UNANSWERED_QUESTION
} from '../constants/action_types'

const initialState = {
    questionsList: [],
    category: [],
    studentCount: null,
    hallSize: null,
    categoryAnswer: [],
    token: null,
    currentId: {},
    surveyGUID: null,
    gnrlAccountInfo: {},
    recomPriorBar: {},
    destinationCategories: [],
    destinationCategoryProperty: [],
    finalBudget: {},
    result: {},
    lastQuesId: null,
    answerSubCategory: {},
    surveyResult: [],
    isSignOff: null,
    selectedDestinationCategoryId: {},
    isSurveyComplete: false,
    unAnswerQuestions:[]
}

function rootsReducer(state = initialState, action) {

    if (action.type === ADD_QUESTIONS) {
        return {
            ...state,
            questionsList: action.payload
        }
    }

    if (action.type === UNANSWERED_QUESTION) {
        return {
            ...state,
            unAnswerQuestions: action.payload
        }
    }

    if (action.type === ADD_CATEGORY) {
        return {
            ...state,
            category: action.payload
        }
    }

    if (action.type === ADD_STUDENT_COUNT) {
        return {
            ...state,
            studentCount: action.payload
        }
    }

    if (action.type === ADD_HALL_SIZE) {
        return {
            ...state,
            hallSize: action.payload
        }
    }

    if (action.type === ADD_ANSWER_CHIPS) {
        return {
            ...state,
            categoryAnswer: action.payload
        }
    }

    if (action.type === ADD_TOKEN) {
        return {
            ...state,
            token: action.payload
        }
    }

    if (action.type === LOG_OUT) {
        return initialState
    }

    if (action.type === ADD_CURRENT_ID) {
        return {
            ...state,
            currentId: { ...action.payload }
        }
    }

    if (action.type === ADD_SURVEY_ID) {
        return {
            ...state,
            surveyGUID: { ...action.payload }
        }
    }

    if (action.type === STORE_GENERAL_INFO) {
        return {
            ...state,
            gnrlAccountInfo: { ...action.payload }
        }
    }

    if (action.type === ADD_RECOM_PRIOR) {
        return {
            ...state,
            recomPriorBar: { ...action.payload }
        }
    }
    if (action.type === SET_DESTINATION_CATEGORIES) {
        return {
            ...state,
            destinationCategories: { ...action.payload }
        }
    }
    if (action.type === SET_DESTINATION_CATEGORY_PROPERTIES) {
        return {
            ...state,
            destinationCategoryProperty: { ...action.payload }
        }
    }
    if (action.type === SET_FINAL_BUDGET) {
        return {
            ...state,
            finalBudget: { ...action.payload }
        }
    }
    if (action.type === ADD_RESULT) {
        return {
            ...state,
            result: { ...action.payload }
        }
    }
    if (action.type === ADD_LAST_ID) {
        return {
            ...state,
            lastQuesId: { ...action.payload }
        }
    } if (action.type === SET_SURVEY_RESULT) {
        return {
            ...state,
            surveyResult: { ...action.payload }
        }
    }
    if (action.type === ADD_SUB_CATEGORY_ID) {
        return {
            ...state,
            answerSubCategory: { ...action.payload }
        }
    }
    if (action.type === ADD_SIGNOFF_FLAG) {
        return {
            ...state,
            isSignOff: { ...action.payload }
        }
    }

    if (action.type === SET_SELECTED_DESTINATION_CATID) {
        return {
            ...state,
            selectedDestinationCategoryId: { ...action.payload }
        }
    }
    if (action.type === SET_SURVEY_COMPLETED) {
        return {
            ...state,
            isSurveyComplete: { ...action.payload }
        }
    }


    if (action.type === 'persist/REHYDRATE') {
        if (action.payload) {
            return {
                ...state,
                category: action.payload.category,
                categoryAnswer: action.payload.categoryAnswer,
                token: action.payload.token,
                hallSize: action.payload.hallSize,
                surveyGUID: action.payload.surveyGUID,
            }
        }
    }

}

export default rootsReducer;