import React, { PureComponent } from "react"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import Splash from "../pages/Splash"
import Login from "../pages/Login"
import App from "../pages/App"
import Results from "../pages/Results"
import Renovation from "../pages/Renovation"
import CateogorySelection from "../pages/CategorySelection"
import FinalBudget from "../pages/FinalBudget"
import { connect } from 'react-redux'
import EmailSurveyPDF from "../pages/EmailSurveyPDF"

class AppContainner extends PureComponent {
  render() {
    
    const PrivateRoute = ({ component: Component, ...rest }) => (
      (<Route {...rest} render={(props) => (
        (this.props.token) 
          ? <Component {...props} Linkdata={props.location.state} disableAtDirect={true} /> 
          : <Redirect to={{
            pathname: '/',
            state: { from: props.location }
          }} />
      )} />)
    )
    
    return (
      <BrowserRouter>
        <React.Suspense fallback={<div />}>
          <Switch>
            <PrivateRoute exact path="/dashboard" component={App} />
            <Route exact path="/splash" component={Splash} />
            <Route exact path="/" component={Login} />            
            <PrivateRoute exact path="/calculator" component={Renovation} />
            <PrivateRoute exact path="/categoryResult" component={Results} />
            <PrivateRoute exact path="/categoryselection" component={CateogorySelection} />
            <PrivateRoute exact path="/finalbudget" component={FinalBudget} />
            <PrivateRoute exact path="/emailSurveyPDF" component={EmailSurveyPDF} />
            <PrivateRoute exact path="/finalbudget" component={FinalBudget} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state) {
  const { token } = state
  return { token }
}

const AppContainer = connect(mapStateToProps)(AppContainner)
export default AppContainer
