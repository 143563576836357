import React, { PureComponent } from "react"
import {
    Col, Card, CardHeader, CardBody
} from 'reactstrap';
  
class HalfSize extends PureComponent {

    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
      
  render() {
    return (
        <>
            <Col className="pr-xl-2">
                <Card className="border-0 rounded-0 mb-3 hall-size">
                    <CardHeader className="font-weight-bold text-uppercase text-white py-2">NO. OF SEATS</CardHeader>
                    <CardBody className="p-2">
                        {this.props.dinningSize}
                    </CardBody>
                </Card>
            </Col>
        </>
    )
  }
}
export default HalfSize
