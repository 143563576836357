import React, { PureComponent } from "react"
import {
  Row, Col, Card, CardHeader, CardBody, Progress
} from 'reactstrap';
import { connect } from 'react-redux'
import _ from 'lodash'

class RecommendationsBar extends PureComponent {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  renderBar = (recomValue) => {
    let recom_arr = []
    recomValue.map((value, index) => {
      if (parseInt(value.value) === -1) {
        recom_arr.push(
          <Row key={index} className="mb-2 mx-1">
            <Col className="bar-disabled-text p-0">{value['name']}</Col>
            <Col xs="8" className="align-self-center pr-0"><Progress className="bar-disabled" color="secondary" value={0} /></Col>
          </Row>
        )
      } else {
        recom_arr.push(
          <Row key={index} className="mb-2 mx-1">
            <Col className="p-0">{value['name']}</Col>
            <Col xs="8" className="align-self-center pr-0"><Progress className="progress-dark-blue" value={value['value']} /></Col>
          </Row>
        )
      }
      return true
    })
    return recom_arr
  }

  renderStaticRecom = () => {
    const static_recom_arr = []
    const dummyRecom = ['Get Together', 'Go For It', 'Explore']
    dummyRecom.map((value, index) => {
      static_recom_arr.push(
        <Row key={index} className="mb-2 mx-1">
          <Col className="p-0">{value}</Col>
          <Col xs="8" className="align-self-center pr-0"><Progress className="progress-dark-blue" value="0" /></Col>
        </Row>
      )
      return true
    })

    return static_recom_arr
  }

  renderRecommendationBar = () => {
    const { recomPriorBar, currentId, category } = this.props
    let recom_arr = []

    if (recomPriorBar) {
      let subCategoryId = currentId['currntSubCategoryId']
      let categoryId = currentId['currntCategoryId']

      if (subCategoryId === 0) {
        recom_arr = this.renderStaticRecom()
      } else {
        if (recomPriorBar[subCategoryId]) {

          const { recommendation } = recomPriorBar[subCategoryId]
          recom_arr = this.renderBar(recommendation)

        } else if (category) {
          if (category.length > 0) {
            let categoryObj = _.find(category, { categoryId: categoryId })
            const { subCategories } = categoryObj
            let subCategoryObj = _.find(subCategories, { subCategoryId: subCategoryId })
            const { recommendations } = subCategoryObj
            recom_arr = this.renderBar(recommendations)
          }
        } else {
          recom_arr = this.renderStaticRecom()
        }
      }
    } else {
      recom_arr = this.renderStaticRecom()
    }

    return recom_arr
  }

  render() {
    return (
      <>
        <Card className="border-0 rounded-0 mb-3 recommendations">
          <CardHeader className="font-weight-bold text-uppercase text-white py-2">Recommendations</CardHeader>
          <CardBody className="p-2">
            {this.renderRecommendationBar()}
          </CardBody>
        </Card>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { recomPriorBar, currentId, category } = state
  return { recomPriorBar, currentId, category }
}

const Recommendations = connect(mapStateToProps)(RecommendationsBar)
export default Recommendations
