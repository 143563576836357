import React, { PureComponent } from "react"
import {
    Container, Row, Col
} from 'reactstrap'
import Header from "../../common/Header";
import Categories from "../App/components/Categories";
import Result from "./components";

  
class Results extends PureComponent {
      
  render() {
    const { categoryId } = this.props.Linkdata
    return (
        <>
          <Header/>
          <Container fluid className="h-100 w-100">
              <Row className="app-content">
                  <Categories/>
                  <Col className="pl-0" style={{height:"calc(100vh - 110px)"}}>
                    <Result categoryId={categoryId}/>
                  </Col>
              </Row> 
          </Container>
        </>
    )
  }
}
export default Results
