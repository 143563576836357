import { createStore } from "redux"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from "../reducers/index"

const persistConfig = {
    key: 'root',
    storage,
    debug: true,
}

const persistedReducer  = persistReducer(persistConfig, rootReducer)

let store = createStore(persistedReducer, window.devToolsExtension && window.devToolsExtension())
let persistor = persistStore(store)

export {
    store,
    persistor
}