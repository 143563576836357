const fetchAPIHelper = async (url, token) => {
    return (fetch(url, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Authorization': 'bearer '+token['token'],
            'Content-Type': 'application/json'
        }
    }).then(response => response.json())
        .then(data => {
            if (data) {
                return data
            } else {
                console.log('error at call API')
            }
        }));
}

export default (fetchAPIHelper);