import React, { PureComponent } from "react"
import { Table, Input, Label, Form, FormGroup, Button } from 'reactstrap'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { cloneDeep } from "lodash"
import _ from 'lodash'
import { setFinalBudget, setSelectedDestinationCategoryId } from '../../../../../../../actions/index'
import TokenValidity from '../../../../../../../helper/TokenValidity'
import Select from 'react-select';
import { baseurl } from "../../../../../../../constants/declaration"

let totalSqft = 0;
let totalRev = 0;
let totalFte = 0;
let totalLabour = 0;
function mapDispatchToProps(dispatch) {
    return {
        setFinalBudget: finalBudget => dispatch(setFinalBudget(finalBudget)),
        setSelectedDestinationCategoryId: selectedDestinationCategoryId => dispatch(setSelectedDestinationCategoryId(selectedDestinationCategoryId)),
    }
}
class RenovationTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            destinationCategoryPropertyList: this.props.destinationCategoryProperty.destinationCategoryProperties.destinationProperties,
            isNewCalculator: this.props.destinationCategoryProperty.isNewCalculator,
            seatsReq: this.props.destinationCategoryProperty.isNewCalculator ? 0 : Number(this.props.destinationCategoryProperty.destinationCategoryProperties.numberOfSeats),
            swipeStations: this.props.destinationCategoryProperty.isNewCalculator ? 0 : Number(this.props.destinationCategoryProperty.destinationCategoryProperties.numberOfSwipeStation),
            isSignOff: this.props.isSignOff.signOffFlag,
            activateSubmit: false,
            swipeStationActive: false,
            generalLabourActive: false,
            swipeStationInput: false,
            seatsReqInput: false,
            swipeStationIndividualProp: false,
            validateSwipe:true,
            validateSeats:true,
        };
    }
    componentDidMount() {
        let genLabourIndex = _.findIndex(this.state.destinationCategoryPropertyList, { 'destinationPropertyName': 'General Labor' });
        let conSeatIndex = _.findIndex(this.state.destinationCategoryPropertyList, { 'destinationPropertyName': 'Consumer Seating' });
        let swipeIndex = _.findIndex(this.state.destinationCategoryPropertyList, { 'destinationPropertyName': 'Swipe Station' });
        if (this.state.destinationCategoryPropertyList[swipeIndex].offered === "Yes") {
            this.setState({ swipeStationIndividualProp: true })
        }
        if (this.state.destinationCategoryPropertyList[genLabourIndex].offered === "Yes") {
            this.setState({ swipeStationInput: true })
        }
        if (this.state.destinationCategoryPropertyList[conSeatIndex].offered === "Yes") {
            this.setState({ seatsReqInput: true })
        }
        if (this.state.signOffFlag) {
            this.setState({ seatsReqInput: false, swipeStationInput: false })
        }

        let destinationCategoryId = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationCategoryId
        let localList = cloneDeep(this.state.destinationCategoryPropertyList);
        localList.map((value, i) => {
            if (value.destinationPropertyName === "Consumer Seating") {
                value.squareFit = (this.state.seatsReq * 15);
            }
            return true
        })

        if (destinationCategoryId === 1) {
            localList.map((value, i) => {
                if (value.destinationPropertyName === "Consumer Seating") {
                    value.propertyValue = (this.state.seatsReq * 700);
                }

                if (value.destinationPropertyName === "Swipe Station") {
                    value.propertyValue = (this.state.swipeStations * 25000);
                    console.log('update swipe', value.propertyValue)
                }
                return true
            })
        }

        this.setState({ destinationCategoryPropertyList: localList })
    }
    renderRows = () => {
        this.setState({ activateSubmit: false })
        totalSqft = 0;
        totalRev = 0;
        totalFte = 0;
        totalLabour = 0;

        let destinationCategoryRenderList = [];
        let genLabourIndex = _.findIndex(this.state.destinationCategoryPropertyList, { 'destinationPropertyName': 'General Labor' });
        this.state.destinationCategoryPropertyList.map((value, i) => {
            if (value.offered === "Yes") {
                this.setState({ activateSubmit: true })
            }
            if (this.state.destinationCategoryPropertyList[genLabourIndex].offered === "Yes") {
                this.setState({ generalLabourActive: true })
            }
            destinationCategoryRenderList.push(
                <tr key={value.destinationPropertyId} >
                    <td width="28%" className="align-middle text-left"> {value.destinationPropertyName}</td>
                    <td width="8%" className="align-middle value-offered-text-color text-left">
                        {this.state.isSignOff ? <span className={value.offered === "Yes" ? "text-success" : "text-danger"}>{value.offered}</span> :
                            <Select placeholder={value.offered} value={value.offered} className={value.offered === "Yes" ? "text-green" : "text-red"} isSearchable={false} options={[
                                { value: "Yes", className: "text-success", label: "Yes", listindex: i },
                                { value: "No", className: "text-danger", label: "No", listindex: i }
                            ]} onChange={e => this.changeoffered(e)}>
                            </Select>}
                    </td>
                    {value.destinationPropertyName !== "Swipe Station" ?
                        <>
                            <td width="16%" className="align-middle text-right">{value.offered === "Yes" ? value.squareFit : ''}</td>
                            <td width="16%" className="align-middle text-right">{value.offered === "Yes" ? '$' + value.propertyValue : ''}</td>
                            <td width="16%" className="align-middle text-right">{value.offered === "Yes" ? value.fteRequired : ''}</td>
                            <td width="16%" className="align-middle text-right">{value.offered === "Yes" ? '$' + value.laborCost : ''}</td>
                        </>
                        :
                        <>
                            <td width="16%" className="align-middle text-right">{this.state.generalLabourActive ? value.squareFit : ''}</td>
                            <td width="16%" className="align-middle text-right">{this.state.generalLabourActive ? value.propertyValue !== 0 ? '$' + value.propertyValue : 0 : ''}</td>
                            <td width="16%" className="align-middle text-right">{this.state.swipeStationIndividualProp ? value.fteRequired : ''}</td>
                            <td width="16%" className="align-middle text-right">{this.state.swipeStationIndividualProp ? '$' + value.laborCost : ''}</td>
                        </>
                    }
                </tr>
            )
            if (value.offered === "Yes" || value.destinationPropertyName === "Swipe Station") {

                if (value.destinationPropertyName === "Swipe Station" && this.state.destinationCategoryPropertyList[genLabourIndex].offered === "No") {
                    totalRev = totalRev + 0;
                    totalFte = totalFte + 0;
                    totalSqft = totalSqft + 0;
                    totalLabour = totalLabour + 0;
                } else {
                    totalRev = totalRev + value.propertyValue;
                    totalFte = totalFte + value.fteRequired;
                    totalSqft = totalSqft + value.squareFit;
                    totalLabour = totalLabour + value.laborCost;
                }
            }
            return true
        })
        return destinationCategoryRenderList;
    }

    changeoffered(event) {
        let tempList = cloneDeep(this.state.destinationCategoryPropertyList);
        tempList[event.listindex].offered = event.value;
        let destinationCategoryId = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationCategoryId

        tempList.map((value, index) => {
            if (index === event.listindex) {
                if (destinationCategoryId === 1) {
                    if (tempList[event.listindex].destinationPropertyName === "General Labor") {
                        let swipeStationIndex = _.findIndex(tempList, { 'destinationPropertyName': 'Swipe Station' });
                        if (tempList[event.listindex].offered === "Yes") {
                            this.setState({ swipeStationIndividualProp: true })
                            this.setState({ generalLabourActive: true })
                            tempList[swipeStationIndex].offered = "Yes";
                            this.setState({ swipeStationInput: true, swipeStations: 2 })
                            tempList[swipeStationIndex].squareFit = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationProperties[swipeStationIndex].squareFit
                            tempList[swipeStationIndex].propertyValue = (2 * 25000)
                        } else {
                            this.setState({ generalLabourActive: false })
                            this.setState({ swipeStationInput: false, swipeStations: 0 })
                            tempList[swipeStationIndex].squareFit = 0
                            tempList[swipeStationIndex].propertyValue = 0
                        }
                    }

                    if (tempList[event.listindex].destinationPropertyName === "Swipe Station") {
                        this.setState({ swipeStationActive: true })
                        if (!this.state.generalLabourActive) {
                            tempList[event.listindex].squareFit = 0
                            tempList[event.listindex].propertyValue = 0
                        }
                        if (tempList[event.listindex].offered === "No") {
                            this.setState({ swipeStationIndividualProp: false })
                            tempList[event.listindex].fteRequired = 0
                            tempList[event.listindex].laborCost = 0
                        } else {
                            this.setState({ swipeStationIndividualProp: true })
                        }
                    }
                } else if (destinationCategoryId === 2) {
                    if (tempList[event.listindex].destinationPropertyName === "General Labor") {
                        let swipeStationIndex = _.findIndex(tempList, { 'destinationPropertyName': 'Swipe Station' });

                        if (tempList[event.listindex].offered === "Yes") {
                            this.setState({ swipeStationIndividualProp: true })
                            this.setState({ generalLabourActive: true })
                            this.setState({ swipeStationInput: true, swipeStations: 2 })
                            tempList[swipeStationIndex].offered = "Yes";
                            tempList[swipeStationIndex].squareFit = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationProperties[swipeStationIndex].squareFit
                        } else {
                            this.setState({ generalLabourActive: false })
                            this.setState({ swipeStationInput: false, swipeStations: 0 })
                            tempList[swipeStationIndex].squareFit = 0
                        }
                    }

                    if (tempList[event.listindex].destinationPropertyName === "Swipe Station") {
                        this.setState({ swipeStationActive: true })
                        if (!this.state.generalLabourActive) {
                            tempList[event.listindex].squareFit = 0
                        }

                        if (tempList[event.listindex].offered === "No") {
                            this.setState({ swipeStationIndividualProp: false })
                            tempList[event.listindex].fteRequired = 0
                            tempList[event.listindex].laborCost = 0
                        } else {
                            this.setState({ swipeStationIndividualProp: true })
                        }
                    }
                }
                else if (destinationCategoryId === 3) {
                    if (tempList[event.listindex].destinationPropertyName === "General Labor") {
                        let swipeStationIndex = _.findIndex(tempList, { 'destinationPropertyName': 'Swipe Station' });

                        if (tempList[event.listindex].offered === "Yes") {
                            this.setState({ swipeStationIndividualProp: true })
                            this.setState({ generalLabourActive: true })
                            this.setState({ swipeStationInput: true, swipeStations: 2 })
                            tempList[swipeStationIndex].offered = "Yes";
                            tempList[swipeStationIndex].squareFit = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationProperties[swipeStationIndex].squareFit
                        } else {
                            this.setState({ generalLabourActive: false })
                            this.setState({ swipeStationInput: false, swipeStations: 0 })
                            tempList[swipeStationIndex].squareFit = 0

                        }
                    }

                    if (tempList[event.listindex].destinationPropertyName === "Swipe Station") {
                        this.setState({ swipeStationActive: true })
                        if (!this.state.generalLabourActive) {
                            tempList[event.listindex].squareFit = 0
                        }
                        if (tempList[event.listindex].offered === "No") {
                            this.setState({ swipeStationIndividualProp: false })
                            tempList[event.listindex].fteRequired = 0
                            tempList[event.listindex].laborCost = 0
                        } else {
                            this.setState({ swipeStationIndividualProp: true })
                        }
                    }
                }

                if (tempList[event.listindex].destinationPropertyName === "Consumer Seating") {
                    let swipeStationIndex = _.findIndex(tempList, { 'destinationPropertyName': 'Consumer Seating' });
                    if (tempList[event.listindex].offered === "Yes") {
                        this.setState({ seatsReqInput: true, seatsReq: 50 })
                        tempList[swipeStationIndex].squareFit = (50 * 15);

                        if (destinationCategoryId === 1) {
                            tempList[swipeStationIndex].propertyValue = (50 * 700)
                        } else {
                            tempList[swipeStationIndex].propertyValue = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationProperties[swipeStationIndex].propertyValue
                        }

                    } else {
                        this.setState({ seatsReqInput: false, seatsReq: 0 })
                        tempList[swipeStationIndex].squareFit = 0
                    }
                }
            }
            return true
        })
        this.setState({ destinationCategoryPropertyList: tempList }, this.forceUpdate());
    }
    createFinalBudget = async () => {
        let finalBudget = {};
        const { destinationCategoryId } = this.props.destinationCategoryProperty.destinationCategoryProperties
        finalBudget.destinationCategoryId = destinationCategoryId;
        finalBudget.numberOfSwipeStation = Number(this.state.swipeStations);
        finalBudget.numberOfSeats = Number(this.state.seatsReq);
        finalBudget.totalSquareFit = totalSqft;
        finalBudget.totalPropertyValue = totalRev;
        finalBudget.totalFTERequired = totalFte;
        finalBudget.totalLaborCost = totalLabour;
        finalBudget.destinationProperties = this.state.destinationCategoryPropertyList;
        this.props.setFinalBudget(finalBudget);
        this.props.setSelectedDestinationCategoryId({ selectedDestinationCategoryId: destinationCategoryId, openBudget: true });
        await TokenValidity.checkTokenValidity(baseurl + '/SurveyCalculator/destination/property/save', finalBudget, this.props.token)
        this.setState({ navigateTo: true });
    }

    handleSeatsRequiredChange = (e) => {
        this.setState({ seatsReq: e.target.value });
        let destinationCategoryId = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationCategoryId

        if (Number(e.target.value) > 0) {
            this.setState({ validateSeats: true })
        } else {
            this.setState({ validateSeats: false })
        }


        let localList = cloneDeep(this.state.destinationCategoryPropertyList);
        localList.map((value, i) => {
            if (value.destinationPropertyName === "Consumer Seating") {
                value.squareFit = (e.target.value * 15);
            }
            return true
        })

        // if (destinationCategoryId === 1) {
        //     localList.map((value, i) => {
        //         if (value.destinationPropertyName === "Consumer Seating") {
        //             value.propertyValue = (e.target.value * 700);
        //         }
        //         return true
        //     })
        // }

        if (parseInt(e.target.value) <= 0 || e.target.value === '') {
            localList.map((value, i) => {
                if (value.destinationPropertyName === "Consumer Seating") {
                    value.squareFit = 0;
                    value.propertyValue = 0;
                }
                return true
            })
        } else {
            localList.map((value, i) => {
                if (value.destinationPropertyName === "Consumer Seating") {
                    value.squareFit = (e.target.value * 15);
                    if (destinationCategoryId === 1) {
                        value.propertyValue = (e.target.value * 700)
                    } else {
                        value.propertyValue = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationProperties[i].propertyValue;
                    }
                }
                return true
            })
        }

        this.setState({ destinationCategoryPropertyList: localList })
    };

    handleSwipeStationChange = (e) => {
        this.setState({ swipeStations: e.target.value });
        let destinationCategoryId = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationCategoryId
        let localList = cloneDeep(this.state.destinationCategoryPropertyList);

        if (Number(e.target.value) > 0) {
            this.setState({ validateSwipe: true})
        } else {
            this.setState({ validateSwipe: false })
        }

        if (destinationCategoryId === 1) {
            localList.map((value, i) => {
                if (value.destinationPropertyName === "Swipe Station") {
                    value.propertyValue = (e.target.value * 25000);
                }
                return true
            })
        }

        if (parseInt(e.target.value) <= 0 || e.target.value === '') {
            localList.map((value, i) => {
                if (value.destinationPropertyName === "Swipe Station") {
                    value.squareFit = 0;
                    value.propertyValue = 0;
                }
                return true
            })
        } else {
            localList.map((value, i) => {
                if (value.destinationPropertyName === "Swipe Station") {
                    value.squareFit = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationProperties[i].squareFit;
                    if (destinationCategoryId === 1) {
                        value.propertyValue = (e.target.value * 25000);
                    } else if (destinationCategoryId === 2) {
                        value.propertyValue = this.props.destinationCategoryProperty.destinationCategoryProperties.destinationProperties[i].propertyValue;
                    }
                }
                return true
            })
        }
        this.setState({ destinationCategoryPropertyList: localList })
    };

    render() {
        if (this.state.navigateTo) {
            return <Redirect to={{ pathname: '/finalbudget' }} />
        }
        const { validateSeats, validateSwipe, activateSubmit } = this.state
        return (
            <>
                <Table borderless size="sm" className="shadow-sm position-relative text-center m-0">
                    <thead>
                        <tr className="border">
                            <th style={{ lineHeight: "28px" }} className="align-middle text-left" width="28%">Destination</th>
                            <th width="8%" className="align-middle">Offered</th>
                            <th width="16%" className="text-right align-middle">Sq.Ft.</th>
                            <th width="16%" className="text-right align-middle">{this.props.destinationCategoryProperty.selectedDestinationCategoryName}</th>
                            <th width="16%" className="text-right align-middle">FTE's Required</th>
                            <th width="16%" className="text-right align-middle pr-3">Labor Cost</th>
                        </tr>
                    </thead>
                </Table>

                <div className="scroll-external">
                    <Table borderless hover striped size="sm" className="text-center m-0 ">
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </Table>
                </div>

                <Table borderless size="sm" className="text-center">
                    <tfoot>
                        <tr style={{ background: "rgba(138, 185, 251, 0.35)" }}>
                            <th width="28%" style={{ lineHeight: "26px" }} className="align-middle text-left">Total</th>
                            <th width="8%"></th>
                            <th width="16%" className="align-middle text-right">{totalSqft}</th>
                            <th width="16%" className="align-middle text-right">${totalRev !== 0 ? totalRev.toFixed(2) : 0}</th>
                            <th width="16%" className="align-middle text-right">{totalFte.toFixed(2)}</th>
                            <th width="16%" className="align-middle text-right pr-3"> ${totalLabour.toFixed(2)}</th>
                        </tr>
                    </tfoot>
                </Table>

                <Form inline className="justify-content-center my-4">
                    <div className="row">
                        <FormGroup className="col-md-5 mb-2 mb-sm-0">
                            <Label sm={6} for="SwipeStations" className="mr-sm-2 font-weight-bold">Enter the number of swipe stations</Label>
                            <Input sm={4} disabled={!this.state.swipeStationInput || this.state.isSignOff} type="number" min="0" name="swipeStations" id="SwipeStations" onChange={e => this.handleSwipeStationChange(e)} value={this.state.swipeStations} />
                            {!validateSwipe && <div className="mandatory-field error-calc text-center">Swipe Stations be greater than 0.</div>}

                        </FormGroup>
                        <FormGroup className="col-md-5 mb-2 mb-sm-0">
                            <Label sm={6} for="SeatsRequired" className="mr-sm-2 font-weight-bold">Enter the number of seats required</Label>
                            <Input sm={4} disabled={!this.state.seatsReqInput || this.state.isSignOff} type="number" min="0" required name="seatsReq" id="SeatsRequired" onChange={e => this.handleSeatsRequiredChange(e)} value={this.state.seatsReq} />
                            {!validateSeats && <div className="mandatory-field error-calc text-center">Seats should be greater than 0.</div>}

                        </FormGroup>
                        
                        <FormGroup className="col-md-2 mb-2 mb-sm-0">
                            <Button color="primary" onClick={e => this.createFinalBudget(e)} disabled={!activateSubmit || !validateSwipe || !validateSeats}>Submit</Button>
                        </FormGroup>
                    </div>
                </Form>

            </>
        )
    }
}

function mapStateToProps(state) {
    return { token: state.token, destinationCategoryProperty: state.destinationCategoryProperty, isSignOff: state.isSignOff };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenovationTable)
