import jwt from 'jsonwebtoken'
import fetchPOSTAPIHelper from './fetchPOSTAPIHelper'
import fetchAPIHelper from './fetchAPIHelper'
import { baseurl } from '../constants/declaration'
import { connect } from 'react-redux'
import { addToken } from '../actions/index'
import { PureComponent } from "react"
import { store } from '../store/index';
import { logOut } from '../actions/index'

function mapDispatchToProps(dispatch) {
    return {
        addToken: token => dispatch(addToken(token)),
        logOut: state => dispatch(logOut(state)),
    }
}

class TokenValidity extends PureComponent {

    static checkTokenValidity = async (url, payload, tokn) => {
        if (tokn['token']) {
            const { token } = tokn
            let decoded = jwt.decode(token)
            const { exp, refreshToken } = decoded
            if (exp < Date.now() / 1000) {
                let newToken = await this.callRefreshToken(refreshToken, tokn)

                if (!newToken.isSucceed) {
                    store.dispatch(logOut(null))
                    store.dispatch(addToken(null))
                    window.location = window.location.origin
                } else {
                    let addTokn = { 'token': newToken['token'] }
                    store.dispatch(addToken(addTokn))
                    let apiData = this.fetchData(url, payload, newToken)
                    return apiData
                }
            } else {
                let apiData = this.fetchData(url, payload, tokn)
                return apiData
            }
        } else {
            let postAPIData = await fetchPOSTAPIHelper(url, payload, tokn)
            return postAPIData
        }
    }

    static fetchData = async (url, payload, tokn) => {
        if (Object.entries(payload).length !== 0) {
            let postAPIData = await fetchPOSTAPIHelper(url, payload, tokn)
            return postAPIData
        } else {
            let getAPIData = await fetchAPIHelper(url, tokn)
            return getAPIData
        }
    }

    static callRefreshToken = async (refrshToken, tokn) => {
        let payload = {
            "refreshToken": refrshToken,
            "token": tokn['token']
        }

        return (fetch(baseurl + '/Customer/refreshtoken', {
            'method': 'POST',
            'body': JSON.stringify(payload),
            'Content-Type': 'application/json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + tokn['token'],
            },
        }).then(response => response.json())
            .then(data => {
                if (data) {
                    return data
                } else {
                    console.log('error at call refresh API')
                }
            }));
    }
}

export default connect(null, mapDispatchToProps)(TokenValidity);