import React from "react"
import { render } from "react-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import './styles/style.css';
import AppContainer from "./containers/AppContainer"
import { Provider } from 'react-redux'
import { store, persistor } from './store/'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'

const WebApp = () => (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>        
        <AppContainer />
        <ToastContainer autoClose={3000} hideProgressBar={true} />
      </PersistGate>
    </Provider>
)

render(<WebApp />, document.getElementById("root"))

