import React, { PureComponent } from "react"
import Header from "../../common/Header"
import {
  Container, Row, Col
} from 'reactstrap'
import Categories from "./components/Categories"
import SidebarRight from "./components/SidebarRight"
import GeneralAccountInfo from "../GeneralAccountInfo";
import Answers from "./components/Answers";
import Questions from "../Questions";

class App extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  renderAppAtLinkData = () => {
    const { disable, categoryId, subCategoryId, questionId, disableSubmitButton } = this.props.Linkdata

    return (
      <Row className="app-content">
        <Categories disableMenu={disable} categoryId={categoryId} subCategoryId={subCategoryId} />
        <Col className="p-lg-0" style={{ height: "calc(100vh - 110px)" }}>
          {disable && <GeneralAccountInfo />}
          {!disable && <Questions subCategoryId={subCategoryId} categoryId={categoryId} quesId={questionId} />}
          {!disable && <Answers categoryId={categoryId} subCategoryId={subCategoryId} />}
        </Col>
        <SidebarRight disableButton={disableSubmitButton} categoryId={categoryId} subCategoryId={subCategoryId} currentQuestionId={questionId} />
      </Row>
    )
  }

  renderApp = () => {
    const { disableAtDirect } = this.props
    return (
      <Row className="app-content">
        <Categories disableMenu={disableAtDirect} categoryId={1} subCategoryId={1} />
        <Col className="p-lg-0" style={{ height: "calc(100vh - 110px)" }}>
          {disableAtDirect && <GeneralAccountInfo />}
        </Col>
        <SidebarRight disableButton={true} categoryId={1} subCategoryId={1} currentQuestionId={1}/>
      </Row>
    )
  }

  render() {
    return (
      <>
        <Header  disable={this.props.Linkdata ? this.props.Linkdata.disable : true}/>
        <Container fluid className="h-100 w-100">
          { (this.props.Linkdata) && this.renderAppAtLinkData() }
          { !this.props.Linkdata && this.renderApp() }
        </Container>
      </>
    )
  }
}

export default App
