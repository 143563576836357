import React, { PureComponent } from "react"
import {
  Row, Col
} from 'reactstrap';
import HalfSize from "./HallSize";
import NoOfStudents from "./NoOfStudents";
import Prioritization from "./Prioritization";
import Recommendations from "./Recommendations";
import SubmitAnswer from "./SubmitAnswer";
import { connect } from 'react-redux'

class Sidebar extends PureComponent {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  renderStudentCount = () => {
    const { studentCount } = this.props
    if (studentCount) {
      return <NoOfStudents studentCount={studentCount['studentCount']} />
    }
    else {
      return <NoOfStudents studentCount='' />
    }
  }

  renderHallSize = () => {
    const { hallSize } = this.props
    if (hallSize) {
      return <HalfSize dinningSize={hallSize['dinningHall']} />
    }
    else {
      return <HalfSize dinningSize='' />
    }
  }

  render() {
    const { disableButton, categoryId, subCategoryId, currentQuestionId } = this.props

    return (
      <>
        <Col sm="3"  className="sidebar-right">
          <div className="shadow h-100 p-2 d-flex flex-column">
            <Recommendations />
            <Prioritization />
            <Row>
              {this.renderHallSize()}
              {this.renderStudentCount()}
            </Row>
            {<SubmitAnswer deactivate={disableButton} categoryId={categoryId} subCategoryId={subCategoryId} currentQuestionId={currentQuestionId}/>}
          </div>
        </Col>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { studentCount, hallSize } = state
  return { studentCount, hallSize }
}

const SidebarRight = connect(mapStateToProps)(Sidebar);
export default SidebarRight
