import React, { PureComponent } from "react"
import AppLogo from "../../assets/images/app-logo.png"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Alert
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { logOut, setDestinationCategoryProperties, setFinalBudget } from '../../actions/index'
import jwt from 'jsonwebtoken'
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import TokenValidity from "../../helper/TokenValidity"
import { baseurl } from "../../constants/declaration"

function mapDispatchToProps(dispatch) {
  return {
    logOut: state => dispatch(logOut(state)),
    setDestinationCategoryProperties: destinationCategoryProperty => dispatch(setDestinationCategoryProperties(destinationCategoryProperty)),
    setFinalBudget: finalBudget => dispatch(setFinalBudget(finalBudget)),
  }
}

class HeaderComponent extends PureComponent {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      loggedOut: false,
      navigateToBudget: false,
      selectedDestinationCategoryId: this.props.selectedDestinationCategoryId.selectedDestinationCategoryId,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  logOut = () => {
    const cookies = new Cookies();
    cookies.remove('rememberMe');
    this.setState({ loggedOut: true }, () => { this.props.logOut(null) })
  }

  renderUserName = () => {
    if (this.props.token) {
      const { username } = jwt.decode(this.props.token['token'])
      return <> <FontAwesomeIcon icon={faUser} className="mr-2" /> {username} </>
    }
  }
  navigateToBudgetPage = async () => {
    let categories = this.props.destinationCategories[0];

    let destinationCategoryProperty = await TokenValidity.checkTokenValidity(baseurl + '/SurveyCalculator/destination/' + this.state.selectedDestinationCategoryId + '/property', {}, this.props.token)
    destinationCategoryProperty.selectedDestinationCategoryName = categories[this.state.selectedDestinationCategoryId - 1].destinationCategoryName;
    this.props.setDestinationCategoryProperties(destinationCategoryProperty)

    let destinationCatProp = destinationCategoryProperty.destinationCategoryProperties

    if (destinationCatProp) {

      let finalBudget = {};
      finalBudget.destinationCategoryId = this.state.destinationCategoryId;
      finalBudget.numberOfSwipeStation = Number(destinationCatProp.numberOfSwipeStation);
      finalBudget.numberOfSeats = Number(destinationCatProp.numberOfSeats);
      finalBudget.totalSquareFit = destinationCatProp.totalSquareFit;
      finalBudget.totalPropertyValue = destinationCatProp.totalPropertyValue;
      finalBudget.totalFTERequired = destinationCatProp.totalFTERequired;
      finalBudget.totalLaborCost = destinationCatProp.totalLaborCost;
      finalBudget.destinationProperties = destinationCatProp.destinationProperties;
      this.props.setFinalBudget(finalBudget);

      this.setState({ navigateToBudget: true })
    }

  }
  renderBudgetButton = () => {
    const { isSurveyComplete, disable } = this.props
    let disabled = disable
    if (disabled === undefined) { disabled = false; }

    if (this.state.selectedDestinationCategoryId && disabled === false && this.props.location.pathname !== '/finalbudget' && isSurveyComplete.isSurveyComplete) {
      return (<Button color="primary" size="sm" className="mr-2" onClick={e => this.navigateToBudgetPage(e)}>Final Budget</Button>)
    }
    else if (this.state.selectedDestinationCategoryId && disabled === false && this.props.location.pathname === '/finalbudget' && !isSurveyComplete.isSurveyComplete) {
      return <Button color="primary" size="sm" className="mr-2" disabled onClick={e => this.navigateToBudgetPage(e)}>Final Budget</Button>
    }
    else {
      return null
    }
  }

  showWarning = () => {
    const { unAnswerQuestions } = this.props
    if (unAnswerQuestions) {
      if (unAnswerQuestions.length > 0) {
        let length = unAnswerQuestions.length
        return (
          <Alert color="danger" className="my-auto d-flex justify-content-center">
            <span className="my-0 text-nowrap">Please fill unanswered questions - </span>

            <span className="text-left d-inline-block px-1">
              {unAnswerQuestions.map((val, id) => {
                return (<span className="mb-1 que-lineheight" key={id}><strong> {val.name}</strong> : Q <strong>{val.questionId}</strong>{length - 1 === id ? '' : <>, &nbsp;</>} </span>)
              })}
            </span>
          </Alert>
        )
      }
    }
  }

  render() {

    const { loggedOut, navigateToBudget } = this.state

    if (navigateToBudget) {
      return (<Redirect to={'/finalbudget'} />)
    }

    if (loggedOut) {
      return <Redirect to="/" />
    }

    return (
      <>
        <Navbar color="white" light expand="md" className="shadow-sm border-bottom mb-2 fixed-top d-block">
          <div>
            <Row className="align-items-center">
              <Col lg={2}>
                <NavbarBrand href="/"><img src={AppLogo} width="82" alt="" /></NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
              </Col>
              <Col lg={7} className="p-0">
                {this.showWarning()}
              </Col>
              <Col lg={3}>
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav navbar className="ml-auto">
                    {this.renderBudgetButton()}
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret className="font-weight-bold pl-2">
                        {this.renderUserName()}
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={this.logOut}>
                          Logout
                  </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </Collapse>
              </Col>
            </Row>
          </div>
        </Navbar>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { token, selectedDestinationCategoryId, destinationCategories, isSurveyComplete, unAnswerQuestions } = state
  return { token, selectedDestinationCategoryId, destinationCategories, isSurveyComplete, unAnswerQuestions }
}

const Header = withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent))
export default Header
