import React, { PureComponent } from "react"
import {
    Container, Row, Col
} from 'reactstrap'
import Header from "../../common/Header";
import Categories from "../App/components/Categories";
import RenovationContent from "./../Results/components/FullCourseCalculator/Renovation/index";

  
class Renovation extends PureComponent {
      
  render() {
    return (
        <>
          <Header/>
          <Container fluid className="h-100 w-100">
              <Row className="app-content">
                  <Categories/>
                  <Col className="pl-0" style={{height:"calc(100vh - 110px)"}}>
                    <RenovationContent/>
                  </Col>
              </Row> 
          </Container>
        </>
    )
  }
}
export default Renovation
