import React, { PureComponent } from "react"
import {
  Container, Row, Col, Card, CardBody, Table, Button
} from 'reactstrap'
import Header from "../../common/Header";
import Categories from "../App/components/Categories";
import { connect } from 'react-redux'
import { setDestinationCategoryProperties } from '../../actions/index'
import SurveySignOff from "./components/SurveySignOff";
import { Redirect } from 'react-router'
import TokenValidity from '../../helper/TokenValidity'
import { baseurl } from '../../constants/declaration'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function mapDispatchToProps(dispatch) {
  return {
    setDestinationCategoryProperties: destinationCategoryProperty => dispatch(setDestinationCategoryProperties(destinationCategoryProperty)),
  }
}

class FinalBudget extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { selectedOption: '', navigateToEmailPDF: false, isDownloading: false, isXLDownloading: false }
  }
  navigateToEmailPDF() {
    this.setState({ navigateToEmailPDF: true })
  }

  downloadPdf = async () => {
    const { token } = this.props
    let payload = { 'name': '' }
    this.setState({ isDownloading: true })
    toast('Your pdf will be downloaded shortly. Please do not close the browser.')    
    const fileData = await TokenValidity.checkTokenValidity(baseurl + '/Survey/result/download/pdf', payload, token)
    
    const { filePath } = fileData
    window.open(filePath);

    setTimeout(() => {      
      this.setState({ isDownloading: false })
      if (filePath) {
        toast('Your pdf is downloaded.')
      } else {
        toast(fileData.Description + '.Please try again.')
      }
    }, 3001);
  }

  downloadXl = async () => {
    const { token } = this.props
    let payload = { 'name': '' }
    this.setState({ isXLDownloading: true })
    toast('Your excel will be downloaded shortly. Please do not close the browser.')    
    const fileData = await TokenValidity.checkTokenValidity(baseurl + '/Survey/result/download/excel', payload, token)
    
    const { filePath } = fileData
    window.open(filePath)

    setTimeout(() => {      
      this.setState({ isXLDownloading: false })  
      if(filePath){
        toast('Your excel is downloaded.')
      } else {
        toast(fileData.Description + '.Please try again.')
      }
    }, 3001);
  }

  render() {
    const { isDownloading, isXLDownloading } = this.state
    if (this.state.navigateToEmailPDF) {
      return (<Redirect to={{ pathname: '/emailSurveyPDF' }} />)
    } else {
      return (
        <>
          <Header />
          <Container fluid className="h-100 w-100">
            <Row className="app-content" >
              <Categories />
              <Col sm="7" className="my-auto">
                <Row>
                  <Col sm="9" className="mx-auto">
                    <Card className="shadow rounded-0 border border-light">
                      <CardBody className="pt-0">
                        <h4 className="text-white d-inline-block p-2" style={{ backgroundColor: "#001E61", marginTop: "-50%" }}>Final Budget: <span className="font-weight-bold">For {this.props.destinationCategoryProperty.selectedDestinationCategoryName}</span></h4>
                        <Table borderless size="lg" className="my-4">
                          <tbody>
                            <tr>
                              <td> Number of Swipe Stations:</td>
                              <td className="font-weight-bold text-left">{this.props.finalBudget.numberOfSwipeStation}</td>
                            </tr>
                            <tr>
                              <td> Number of Seats Required:</td>
                              <td className="font-weight-bold text-left">{this.props.finalBudget.numberOfSeats}</td>
                            </tr>
                            <tr>
                              <td> Square Feet:</td>
                              <td className="font-weight-bold text-left">{this.props.finalBudget.totalSquareFit}</td>
                            </tr>
                            <tr>
                              <td> {this.props.destinationCategoryProperty.selectedDestinationCategoryName + ' Cost:'} </td>
                              <td className="font-weight-bold text-left">${this.props.finalBudget.totalPropertyValue.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td> FTE's Required:</td>
                              <td className="font-weight-bold text-left">{this.props.finalBudget.totalFTERequired.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td> Labor Cost:</td>
                              <td className="font-weight-bold text-left">${this.props.finalBudget.totalLaborCost.toFixed(2)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                    <Row className="text-center py-5">
                      <Col xs="12"><h6>Here is your <strong>Full Course Playbook</strong>, You can download or email.</h6></Col>
                      <Col>
                        <Button color="primary" className="mr-3" size="sm" disabled={isDownloading} onClick={e => this.downloadPdf(e)}>DOWNLOAD PLAYBOOK PDF</Button>
                        <Button color="primary" className="mr-3" size="sm" disabled={isXLDownloading} onClick={e => this.downloadXl(e)}>DOWNLOAD PLAYBOOK EXCEL</Button>
                        <Button color="primary" size="sm" onClick={e => this.navigateToEmailPDF(e)}>EMAIL PLAYBOOK</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col sm="3">
                <SurveySignOff></SurveySignOff>
              </Col>
            </Row>
          </Container>
        </>
      )
    }
  }
}

function mapStateToProps(state) {
  return { token: state.token, finalBudget: state.finalBudget, destinationCategoryProperty: state.destinationCategoryProperty };
}

export default connect(mapStateToProps, mapDispatchToProps)(FinalBudget)
