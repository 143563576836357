import React, { PureComponent } from "react"
import { connect } from 'react-redux'
import Breakfast from "../../../../assets/images/breakfast.svg"
import Canteen from "../../../../assets/images/canteen.svg"
import Networking from "../../../../assets/images/networking.svg"
import Budget from "../../../../assets/images/budget.svg"
import {
    Nav,
    NavItem,
    Col, Card, CardHeader, CardBody
} from 'reactstrap'
import TokenValidity from '../../../../helper/TokenValidity'
import { addCategories, addAnswerChips, addHallSize, addAnswerSubCategories, setDestinationCategories } from '../../../../actions/index'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { baseurl } from '../../../../constants/declaration'

function mapDispatchToProps(dispatch) {
    return {
        addCategories: category => dispatch(addCategories(category)),
        addAnswerChips: categoryAnswer => dispatch(addAnswerChips(categoryAnswer)),
        addHallSize: hallSize => dispatch(addHallSize(hallSize)),
        addAnswerSubCategories: answerSubCategory => dispatch(addAnswerSubCategories(answerSubCategory)),
        setDestinationCategories: destinationCategoryProperty => dispatch(setDestinationCategories(destinationCategoryProperty)),
    }
}

class CategoriesMenu extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            categoriesData: [],
            selectedDestinationCategoryId: this.props.selectedDestinationCategoryId,
            calcActive: false,
        };
    }

    async componentDidMount() {
        const { token } = this.props
        const payload = {}

        let categoriesResult = await TokenValidity.checkTokenValidity(baseurl + '/SurveyCalculator/destination/category', {}, this.props.token)

        const categoryObject = await TokenValidity.checkTokenValidity(baseurl + '/question/all', payload, token)
        if (categoryObject) {
            let categoryArr = Object.values(categoryObject)[0]
            this.createCategoryAnswer(categoryArr)
            this.setState({ categoriesData: categoryArr }, () => {
                this.props.addCategories(categoryArr)
            })
        }
        if (categoriesResult) {
            let destinationCategories = categoriesResult.destinationCategory;
            this.props.setDestinationCategories([destinationCategories])
        }
        if (this.props.isSurveyComplete.isSurveyComplete) {
            this.setState({ calcActive: true })
        } else {
            this.setState({ calcActive: false })
        }
    }

    createCategoryAnswer = (categoryArr) => {
        let subCatObject = []
        let allAnswersObject = []

        categoryArr.map((value, id) => {
            value.subCategories.map((subValue, index) => {
                let firstObj = {
                    [subValue.subCategoryId]: {}
                }
                subCatObject.push(firstObj)
                let answersObject = []
                subValue.questions.map((quesValue, id) => {
                    const { answers } = quesValue
                    if (subCatObject[quesValue['subCategoryId'] - 1]) {
                        let obj = {
                            [quesValue.questionId]: answers
                        }
                        answersObject.push(obj)
                    }
                    return true
                })
                allAnswersObject.push(answersObject)
                return true
            })
            return true
        })

        let temp = {}
        subCatObject.map((val, index) => {
            Object.assign(temp, val)
            return true
        })

        allAnswersObject.map((value, id) => {
            value.map((val, index) => {
                Object.keys(val).map((data) => {
                    if (val[parseInt(data)]) {
                        Object.assign(temp[id + 1], val)
                    }
                    return true
                })
                return true
            })
            return true
        })

        if (this.props.categoryAnswer.length === 0) {
            this.props.addAnswerChips(temp)
        }

        if (this.props.answerSubCategory) {
            if (Object.keys(this.props.answerSubCategory).length === 0) {
                this.setCheckMark(allAnswersObject, categoryArr)
            }
        }

        this.setDinningHallSize(temp)
    }

    setDinningHallSize = (temp) => {
        if (temp[1]) {
            if (temp[1][1]) {
                let hallData = {
                    'dinningHall': temp[1][1][0]['value']
                }
                this.props.addHallSize(hallData)
            }
        }
    }

    checkSubCategory = (categoryArr, checkId, allAnswersObject) => {
        let temp = [...categoryArr]

        temp.map((cVal) => {
            let subCat = _.find(cVal.subCategories, { subCategoryId: checkId })
            if (subCat) {
                let acount = {}
                let aCountArr = []
                let status = true

                allAnswersObject[checkId - 1].map((val) => {
                    let QuesObj = _.find(subCat.questions, { questionId: parseInt(Object.keys(val)[0]) })
                    let quesType = QuesObj.questionType.questionTypeName
                    if (parseInt(Object.keys(val)[0]) === QuesObj.questionId && QuesObj.isActive && quesType !== 'InputBox') {
                        let tempObj = val[Object.keys(val)[0]]
                        if (tempObj) {
                            if (tempObj.length > 0) {
                                Object.values(tempObj).map((val) => {
                                    if (val.value === null || val.value === '') {
                                        status = false
                                        acount = {
                                            [checkId]: false
                                        }
                                    }
                                    return true
                                })
                            }
                            if (tempObj.length === 0) {
                                status = false
                                acount = {
                                    [checkId]: false
                                }
                            }
                            if (tempObj.length > 0 && status) {
                                status = true
                                acount = {
                                    [checkId]: true
                                }
                            }
                        } else {
                            acount = {
                                [checkId]: false
                            }
                        }
                    }
                    return true
                })

                let countObj = { ...this.props.answerSubCategory, ...acount }
                aCountArr.push(acount)
                this.props.addAnswerSubCategories(countObj)

                return subCat
            }
            return true
        })
    }

    setCheckMark = (allAnswersObject, categoryArr) => {
        allAnswersObject.map((value, id) => {
            this.checkSubCategory(categoryArr, id + 1, allAnswersObject)
            return true
        })
    }

    renderCardBody = (subCategories, categoryId) => {
        let subMenu = []
        const { answerSubCategory } = this.props
        let activateLink = false
        subCategories.map((value) => {

            if (answerSubCategory) {
                if (answerSubCategory[value['subCategoryId']]) {
                    activateLink = true
                } else {
                    activateLink = false
                }
            } else {
                activateLink = false
            }

            subMenu.push(
                <NavItem key={value['subCategoryId']} className={this.props.subCategoryId === value['subCategoryId'] ? 'text-body py-1 mt-2 px-2 w-100 activeSubCategory' : 'text-body py-1 mt-2 px-2 w-100'}>
                    {activateLink && <Link to={{ pathname: '/dashboard', state: { disable: false, categoryId: categoryId, subCategoryId: value['subCategoryId'], questionId: value['questions'][0]['displayOrder'], disableSubmitButton: true } }} className={"text-body py-2 px-2 " + answerSubCategory ? answerSubCategory[value['subCategoryId']] ? "checkmark " : '' : ''}>{value['subCategoryName']}</Link>}
                    {!activateLink && <Link to={''} className={"text-secondary disabled-link"}> {value['subCategoryName']} </Link>}
                </NavItem>
            )
            return true
        })
        return subMenu
    }

    renderCards = () => {
        const { categoriesData } = this.state
        const { categoryAnswer } = this.props
        let activateLink = false
        let categoryMenu = []
        const categoryIcon = [Breakfast, Canteen, Networking]

        categoriesData.map((value, index) => {

            if (categoryAnswer) {
                if (Object.keys(categoryAnswer[value['subCategories'][0]['subCategoryId'] + 1]).length > 0) {
                    activateLink = true
                } else {
                    activateLink = false
                }
            } else {
                activateLink = false
            }

            categoryMenu.push(
                <Card key={value['categoryId']} className="border-0 rounded-0 mb-2 active">
                    <CardHeader className="border-primary border rounded-0 bg-white category-header font-weight-bold text-uppercase py-2">
                        {activateLink && <Link to={{ pathname: '/dashboard', state: { disable: false, categoryId: value['categoryId'], subCategoryId: value['subCategories'][0]['subCategoryId'], questionId: value['subCategories'][0]['questions'][0]['displayOrder'], disableSubmitButton: true } }}>
                            <img src={categoryIcon[index]} height="20" className="position-absolute category-header-icon" alt="" /> {value['categoryName']}
                        </Link>}
                        {!activateLink && <Link to={{ pathname: '' }} className={"text-body py-2 px-2 disabled-link"}>
                            <img src={categoryIcon[index]} height="20" className="position-absolute category-header-icon" alt="" /> {value['categoryName']}
                        </Link>}
                    </CardHeader>
                    <CardBody className="p-0">
                        <Nav vertical>
                            {this.renderCardBody(value['subCategories'], value['categoryId'])}
                        </Nav>
                    </CardBody>
                </Card>
            )
            return true
        })
        return categoryMenu
    }

    render() {

        const { disableMenu } = this.props

        return (
            <>
                <Col lg="2" className="sidebar-left shadow-0 h-100">
                    <div className={"position-absolute h-100 w-100 bg-white category-overlay" + (disableMenu ? " " : "d-none")}></div>
                    {this.renderCards()}
                    <Card className="border-0 rounded-0 mb-2 ">

                        {
                            this.state.calcActive ? <Link to={{ pathname: '/categoryselection' }}>
                                <CardHeader className="border-primary border rounded-0 category-header font-weight-bold text-uppercase py-2">
                                    <img src={Budget} className="position-absolute category-header-icon" alt="" /> Full Course Calculator
                        </CardHeader>
                            </Link> : <CardHeader className="border-secondary text-secondary border rounded-0 category-header font-weight-bold text-uppercase py-2">
                                    <img src={Budget} className="position-absolute category-header-icon" alt="" /> Full Course Calculator
                        </CardHeader>
                        }

                    </Card>
                </Col>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { token, categoryAnswer, currentId, answerSubCategory, selectedDestinationCategoryId, category, isSurveyComplete, questionsList } = state
    return { token, categoryAnswer, currentId, answerSubCategory, selectedDestinationCategoryId, category, isSurveyComplete, questionsList }
}

const Categories = connect(mapStateToProps, mapDispatchToProps)(CategoriesMenu)
export default Categories
