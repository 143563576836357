import {
  Container, Row, Col, FormGroup, Button, Card, CardBody
} from 'reactstrap'
import Header from "../../common/Header";
import Categories from "../App/components/Categories";
import SurveySignOff from "../FinalBudget/components/SurveySignOff";
import React, { PureComponent } from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import TokenValidity from '../../helper/TokenValidity'
import { baseurl } from '../../constants/declaration'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'react-toastify/dist/ReactToastify.css'

class EmailPDF extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  emailPdf = async (values, resetForm) => {
    let valueList = {}
    Object.assign(valueList, values)
    this.setState({ isLoading: true })
    const { token } = this.props
    const payload = {
      'emailAddresses': valueList.mailList
    }
    toast('Your mail will be delivered soon.')
    resetForm({ mailList: [{ email: '', name: '' }] })
    const emailPdfResult = await TokenValidity.checkTokenValidity(baseurl + '/Survey/result/email', payload, token)

    if (emailPdfResult) {
      if (emailPdfResult.isSucceed) {
        this.setState({ isLoading: false })
        toast('Mail delivered successfully.')
      }
    } else {
      toast('Please try again.')
    }    

  }

  addEmailAddress = (arrayHelpers) => {
    if (arrayHelpers.form.values) {
      let tempEmail = arrayHelpers.form.values.mailList
      let length = tempEmail.length
      if (tempEmail[length - 1]) {
        if (tempEmail[length - 1].email !== '') {
          arrayHelpers.push({ email: '', name: '' })
        }
      }
    }
  }

  EmailSurveyPDF = () => (
    <div>
      <Formik
        initialValues={{ mailList: [{ email: '', name: '' }] }}
        validationSchema={Yup.object().shape({
          mailList: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .min(2, 'too short')
                  .matches(/^[A-Za-z ]+$/, 'Only accept alphabets')
                  .required('Required'),
                email: Yup.string()
                  .email('Invalid email')
                  .required('Required'),
              })
            )
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => this.emailPdf(values, resetForm)}
        render={({ values  }) => (
          <Form>
            <FieldArray
              name="mailList"
              render={arrayHelpers => (
                <>
                  <Card className="my-auto pb-2">
                    <CardBody className="py-0">
                      <h6 className="bg-white d-inline-block px-2 position-relative font-weight-bold" style={{ top: "-10px" }}>Email pdf of entire survey to:</h6>
                      <FormGroup className="mb-0">
                        {values.mailList.map((friend, index) => (
                          <div key={index}>
                            <Row className="py-1">
                              <Col className="pr-1">
                                <Field className="form-control" name={`mailList[${index}].email`} placeholder="Enter Email Id"/>
                                <p className="text-danger m-0 small"> <ErrorMessage name={`mailList[${index}].email`} className="invalid-feedback" /></p>
                              </Col>
                              <Col className="px-1">
                                <Field className="form-control" name={`mailList.${index}.name`} placeholder="Enter Name"/>
                                <p className="text-danger m-0 small">  <ErrorMessage name={`mailList[${index}].name`} className="invalid-feedback" /></p>
                              </Col>
                              <Col xs="1" className="pl-1">
                                <Button color="link" className="delete-icon" disabled={values.mailList.length > 1 ? false : true} onClick={() => arrayHelpers.remove(index)}>
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </FormGroup>
                      <Row>
                        <Col xs="12" className="my-auto text-right">
                          <Button
                            className="px-0 py-1"
                            color="link"
                            type="button"
                            onClick={() => this.addEmailAddress(arrayHelpers)}
                            disabled={!(values.mailList[values.mailList.length - 1].email && values.mailList[values.mailList.length - 1].name)}
                          >
                            + Add another Email Address
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col xs="12" className="text-right mt-3">
                      <Button color="primary" disabled={this.state.isLoading} type="submit">SEND</Button>
                    </Col>
                  </Row>
                </>
              )}
            />
          </Form>
        )}
      />
    </div>
  );

  render() {
    return (
      <>
        <Header />
        <Container fluid className="h-100 w-100">
          <Row className="app-content" >
            <Categories />
            <Col sm="7">
              <div className="py-5">
                <div className="d-flex flex-column">
                  <h3>Email your full course playbook: <span className="font-weight-bold">For {this.props.destinationCategoryProperty.selectedDestinationCategoryName}</span></h3>
                </div>
              </div>
              <Col sm="9" className="mx-auto">
                {this.EmailSurveyPDF()}
              </Col>
            </Col>
            <Col sm="3">
              <SurveySignOff></SurveySignOff>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { token, destinationCategoryProperty } = state
  return { token, destinationCategoryProperty }
}

const EmailSurveyPDF = connect(mapStateToProps)(EmailPDF)
export default (EmailSurveyPDF)