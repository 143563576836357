import React, { PureComponent } from "react"
import { Button, Form, FormGroup, Input, Pagination, PaginationItem, Badge, Row, Col, CustomInput } from 'reactstrap'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Redirect } from 'react-router'
import { setSurveyCompleted, addHallSize, addAnswerChips, addCurrentId, addRecomPriorBar, addQuestions, addLastID, addCategoryResult, addAnswerSubCategories, addCategories } from '../../actions/index'
import TokenValidity from '../../helper/TokenValidity'
import { baseurl } from '../../constants/declaration'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
function mapDispatchToProps(dispatch) {
    return {
        addQuestions: questionsList => dispatch(addQuestions(questionsList)),
        addHallSize: hallSize => dispatch(addHallSize(hallSize)),
        addAnswerChips: categoryAnswer => dispatch(addAnswerChips(categoryAnswer)),
        addCurrentId: currentId => dispatch(addCurrentId(currentId)),
        addRecomPriorBar: recomPriorBar => dispatch(addRecomPriorBar(recomPriorBar)),
        addLastID: lastQuesId => dispatch(addLastID(lastQuesId)),
        addCategoryResult: result => dispatch(addCategoryResult(result)),
        addAnswerSubCategories: answerSubCategory => dispatch(addAnswerSubCategories(answerSubCategory)),
        addCategories: category => dispatch(addCategories(category)),
        setSurveyCompleted: isSurveyComplete => dispatch(setSurveyCompleted(isSurveyComplete)),
    }
}

class Question extends PureComponent {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            questionTxt: null,
            questionType: null,
            options_arr: [],
            answer: [],
            questionId: null,
            quesOrder: null,
            quesTypeId: null,
            quesFact: null,
            toApp: false,
            categryId: null,
            subCatgryId: null,
            values: {},
            chips: {},
            totalQuestion: 0,
            questionIndex: null,
            disbaleSubmit: true,
            disableNext: true,
            buttonValue: {},
            disableQuestion: false,
            showOtherInput: false,
            otherValue: '',
            bars: [],
            nextQuestionObj: null,
            lastQuestionIdOfCategory: null,
            toResult: false,
            answeredSubCategories: {},
            lastQuestonId: null,
            disableOne: {},
            disableTwo: {},
            disableThree: {},
            disableFour: {},
            category: this.props.category,
            disablePrevious: false,
            otherFlag: false,
            disableOthers: false,
            showOtherTextBox: false,
            radioTextBox: false,
            radioOtherValue: '',
            globalCheck: 'Others',
            globalRadio: ''
        };
    }
    fetchQuestionToDisplay = () => {
        const { questionsList, subCategoryId, categoryId, quesId, categoryAnswer } = this.props

        let questionsObj = _.find(questionsList, { subCategoryId: subCategoryId, categoryId: categoryId })
        const { questions } = questionsObj
        let tempHolder = []
        questions.map((value) => {
            if (value.isActive) {
                tempHolder.push(value)
            }
            return true
        })
        this.setState({ totalQuestion: tempHolder.length })

        this.props.addCurrentId({ currntCategoryId: categoryId, currntSubCategoryId: subCategoryId })

        let questionObj = _.find(questions, { subCategoryId: subCategoryId, displayOrder: quesId, isActive: true })
        let qIndex = _.findIndex(questions, { subCategoryId: subCategoryId, displayOrder: quesId, isActive: true })
        if (questionObj) {
            const { questionText, optionItems, questionId, questionType, questionFact, answers, displayOrder } = questionObj
            this.setState({ questionTxt: questionText, quesTypeId: questionType['questionTypeId'], quesFact: questionFact, questionType: questionType['questionTypeName'], answer: answers, options_arr: optionItems, questionId: questionId, toApp: false, questionIndex: qIndex, disableNext: true, quesOrder: displayOrder })
        } else {
            let isHaveQues = this.checkActiveQuestion(questions)
            if (!isHaveQues) {
                let questionsObj = _.find(questionsList, { subCategoryId: this.props.subCategoryId + 1 })
                const { questions } = questionsObj
                let tempHolder = []
                questions.map((value) => {
                    if (value.isActive) {
                        tempHolder.push(value)
                    }
                    return true
                })
                this.setState({ totalQuestion: tempHolder.length })
                this.props.addCurrentId({ currntCategoryId: questionsObj.categoryId, currntSubCategoryId: questionsObj.subCategoryId })

                let questionObj = _.find(questions, { subCategoryId: questionsObj.subCategoryId, questionId: questionsObj['questions'][0]['questionId'], isActive: true })
                let qIndex = _.findIndex(questions, { subCategoryId: questionsObj.subCategoryId, questionId: questionsObj['questions'][0]['questionId'], isActive: true })

                const { questionText, optionItems, questionId, questionType, questionFact, answers, displayOrder } = questionObj
                this.setState({ categryId: questionsObj.categoryId, subCatgryId: questionsObj.subCategoryId, questionTxt: questionText, quesTypeId: questionType['questionTypeId'], quesFact: questionFact, questionType: questionType['questionTypeName'], answer: answers, options_arr: optionItems, questionId: questionId, toApp: false, questionIndex: qIndex, disableNext: true, quesOrder: displayOrder })
            }
        }

        if (categoryAnswer) {
            this.setState({ values: { ...categoryAnswer[subCategoryId] }, chips: { ...categoryAnswer } })
        }
    }

    checkActiveQuestion = (questions) => {
        let isHaveQuestion = false

        questions.map((value, id) => {
            if (value.isActive && value.questionId > this.state.questionId) {
                const { questionText, optionItems, questionId, questionType, questionFact, answers, displayOrder } = value
                this.setState({ questionTxt: questionText, quesTypeId: questionType['questionTypeId'], quesFact: questionFact, questionType: questionType['questionTypeName'], answer: answers, options_arr: optionItems, questionId: questionId, toApp: false, questionIndex: id, disableNext: true, quesOrder: displayOrder })
                isHaveQuestion = true
            }
            return true
        })
        return isHaveQuestion
    }

    componentDidMount = () => {
        this.fetchQuestionToDisplay()

        if (this.state.category) {
            let subCat = this.state.category[this.state.category.length - 1].subCategories;
            let ansCat = this.props.answerSubCategory;
            const lastSubCategoryId = subCat[subCat.length - 1].subCategoryId;
            for (let key in ansCat) {
                if (Number(key) === Number(lastSubCategoryId)) {
                    this.props.setSurveyCompleted({ isSurveyComplete: true });
                }
            }
            Object.keys(ansCat).map((key) => {
                if (ansCat[key] === false) {
                    this.props.setSurveyCompleted({ isSurveyComplete: false });
                } else {
                    this.props.setSurveyCompleted({ isSurveyComplete: true });
                }
                return true
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.quesId !== this.props.quesId) {
            this.fetchQuestionToDisplay()
        } else if (prevProps.subCategoryId !== this.props.subCategoryId) {
            this.fetchQuestionToDisplay()
        }

        const { questionType, options_arr, questionId } = this.state

        if (questionType === "SingleChoice") {
            let globalRadioTrue = null
            const { categoryAnswer, subCategoryId } = this.props
            if (categoryAnswer) {
                if (categoryAnswer[subCategoryId]) {
                    if (categoryAnswer[subCategoryId][questionId]) {
                        let tempArr = categoryAnswer[subCategoryId][questionId]
                        if (tempArr[0]['value']) {
                            this.disableNxt(false)
                        }

                        let findTrueTextBox = _.find(options_arr, { 'showTextbox': true })
                        if (findTrueTextBox) {
                            this.setState({ globalRadio: findTrueTextBox['optionName'] })
                            globalRadioTrue = findTrueTextBox['optionName']
                        }

                        if (tempArr.some(val => val.name === globalRadioTrue)) {
                            let otherValue = _.find(tempArr, { 'name': globalRadioTrue })
                            if (otherValue.value) {
                                let trimValue = otherValue.value.trim()
                                if (trimValue) {
                                    this.disableNxt(false)
                                } else {
                                    this.disableNxt(true)
                                }
                            } else {
                                this.disableNxt(true)
                            }

                            this.setState({ radioTextBox: true, radioOtherValue: otherValue.value })
                        } else {
                            this.setState({ radioTextBox: false, radioOtherValue: '' })
                        }
                    } else {
                        this.disableNxt(true)
                        this.setState({ radioTextBox: false, radioOtherValue: '' })
                    }
                }
            }
        }

        if (questionType === "MultipleChoice") {
            const { categoryAnswer, subCategoryId } = this.props
            const { disableOthers, options_arr } = this.state
            if (disableOthers) {
                this.setState({ disableOthers: false })
            }
            options_arr.map((option) => {
                if (!option.showTextbox) {
                    this.setState({ showOtherTextBox: false })
                }
                return true
            })
            if (categoryAnswer) {
                if (categoryAnswer[subCategoryId]) {
                    if (categoryAnswer[subCategoryId][questionId]) {
                        let multiTempArr = categoryAnswer[subCategoryId][questionId]
                        if (Object.keys(multiTempArr).length === 0) {
                            this.disableNxt(true)
                        } else {
                            this.disableNxt(false)
                        }

                        let showText = _.find(options_arr, { 'showTextbox': true })
                        if (showText) {
                            if (multiTempArr.some(val => val.name === showText.optionName)) {
                                let otherValue = _.find(multiTempArr, { 'name': showText.optionName })
                                if (otherValue.value) {
                                    let trimmedValue = otherValue.value.trim()
                                    if (trimmedValue) {
                                        this.disableNxt(false)
                                    } else {
                                        this.disableNxt(true)
                                    }
                                } else {
                                    this.disableNxt(true)
                                }
                                this.setState({ showOtherTextBox: true, otherValue: otherValue.value })
                            } else {
                                this.setState({ showOtherTextBox: false, otherValue: '' })
                            }
                        }
                    } else {
                        this.disableNxt(true)
                    }
                }
            }
        }

        if (questionType === "MultipleChoiceFourButtons") {
            const { categoryAnswer, subCategoryId } = this.props
            if (categoryAnswer) {
                if (categoryAnswer[subCategoryId]) {
                    if (categoryAnswer[subCategoryId][questionId]) {
                        let multiChoiceTemp = categoryAnswer[subCategoryId][questionId]
                        if (Object.keys(multiChoiceTemp).length < 4) {
                            this.disableNxt(true)
                        } else if (Object.keys(multiChoiceTemp).length === 4) {
                            this.disableNxt(false)
                        }
                    } else {
                        this.disableNxt(true)
                    }
                }
            }
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    renderBadge = () => {
        const { questionsList } = this.props
        const { questionId } = this.state
        let subCategryQuestion = null
        let badge_arr = []

        subCategryQuestion = _.find(questionsList, { subCategoryId: this.props.subCategoryId })
        const { questions } = subCategryQuestion
        questions.map((value, index) => {
            if (value.isActive) {
                badge_arr.push(
                    <PaginationItem key={index} className="p-1">
                        <Badge className={"border-radius__50 p-2 text-dark" + (questionId === value['questionId'] ? ' active pointer' : ' pointer')} color="secondary" id={'Badge' + value['questionId']}>{index + 1}</Badge>
                    </PaginationItem>
                )
            }
            return true
        })
        return badge_arr
    }

    renderOption = () => {
        const { questionType, options_arr, questionId } = this.state
        if (questionType === "SingleChoice") {
            return this.radioOptionHelper(options_arr, questionId)
        }
        if (questionType === "MultipleChoice") {
            return this.drawMultiBox(options_arr, questionId)
        }
        if (questionType === "MultipleChoiceFourButtons") {
            return this.multipleButton(options_arr, questionId)
        }
        if (questionType === "MultipleChoiceFiveButtons") {
            return this.multipleButtonWithCheckBox(options_arr, questionId)
        }
        if (questionType === "InputBox") {
            return this.inputBox(questionId)
        }
    }

    disableNxt = (property) => {
        this.setState({ disableNext: property })
    }

    inputBox = (questionId) => {
        let inputText = ''
        const { values } = this.state
        const { isSignOff } = this.props
        this.disableNxt(false)
        if (values[questionId]) {
            let temp = values[questionId]
            if (temp[0]) {
                if (temp[0]['value']) {
                    inputText = temp[0]['value']
                }
            }
        } else if (questionId === 59 || values[questionId] === '') {
            this.disableNxt(true)
        }

        return (
            <>
                <label>Enter the name of provider</label>
                <Input type="text" id={questionId} disabled={isSignOff['signOffFlag']} value={inputText} onChange={e => this.storeOther(e)} maxLength={25} />
            </>
        )
    }

    radioOptionHelper = (options_arr, questionId) => {
        let radio_option_arr = []
        let globalRadioTrue = null
        let categoryAnswerValue = null
        const { categoryAnswer, subCategoryId, isSignOff } = this.props
        const { radioTextBox, radioOtherValue } = this.state
        let twoOption = options_arr.length === 2 ? true : false

        if (categoryAnswer) {
            if (categoryAnswer[subCategoryId]) {
                if (categoryAnswer[subCategoryId][questionId]) {
                    let tempRadioHolder = categoryAnswer[subCategoryId][questionId]
                    if (tempRadioHolder[0]['value']) {
                        categoryAnswerValue = tempRadioHolder[0]['value']
                    }
                    let findTrueTextBox = _.find(options_arr, { 'showTextbox': true })
                    if (findTrueTextBox) {
                        globalRadioTrue = findTrueTextBox['optionName']
                    }
                }
            }
        }

        options_arr.map((value, index) => {
            radio_option_arr.push(
                <CustomInput key={index} inline type="radio" className={twoOption ? 'centreAlignRadio' : ''} id={questionId + index} disabled={isSignOff['signOffFlag']} name={"radio" + questionId} value={value['optionName']} checked={categoryAnswerValue === value['optionName'] ? true : value['optionName'] === globalRadioTrue && radioTextBox ? true : false} onChange={e => this.storeRadioValue(e, value['showTextbox'])} label={value['optionName']} />
            )
            return true
        })

        if (radioTextBox) {
            let radioOther = ''
            if (radioOtherValue) {
                radioOther = radioOtherValue
            }
            radio_option_arr.push(
                <>
                    <Input key={'textBox'} type="text" id={questionId} disabled={isSignOff['signOffFlag']} value={radioOtherValue === null ? '' : radioOtherValue} onChange={e => { this.storeRadioValue(e, null); }} maxLength={25} />
                    {radioOther.length <= 0 && <div className='mandatory-field'>* This field is mandatory.</div>}</>
            )
        }
        return radio_option_arr
    }

    drawMultiBox = (options_arr, questionId) => {
        let multi_option_arr = []
        let diableOptions = false
        const { categoryAnswer, subCategoryId, isSignOff } = this.props
        const { disableOthers, showOtherTextBox, otherValue } = this.state

        if (options_arr.some(val => (val.disableOtherOptions === true))) {
            if (disableOthers) {
                diableOptions = true
            } else if (categoryAnswer[subCategoryId][questionId]) {
                let temp = categoryAnswer[subCategoryId][questionId]
                options_arr.map((value) => {
                    const { optionName, disableOtherOptions } = value
                    if (temp.some(val => val.value === optionName) && disableOtherOptions) {
                        diableOptions = true
                    }
                    return true
                })
            } else {
                diableOptions = false
            }
        } else {
            diableOptions = false
        }

        options_arr.map((value, index) => {
            if (value['disableOtherOptions']) {
                diableOptions = false
            }
            multi_option_arr.push(
                <CustomInput key={index} inline type="checkbox" disabled={!isSignOff['signOffFlag'] ? diableOptions ? true : false : true} id={questionId + index} value={value['optionName']} checked={categoryAnswer ? categoryAnswer[subCategoryId] ? categoryAnswer[subCategoryId][questionId] ? categoryAnswer[subCategoryId][questionId].some(val => val.value === value['optionName']) ? true : value['optionName'] === 'Others' && showOtherTextBox ? true : false : '' : null : null} onChange={(e) => this.storeCheckBoxValue(e, value['disableOtherOptions'], value['showTextbox'])} label={value['optionName']} />
            )
            return true
        })

        if (showOtherTextBox) {
            let other = ''
            if (otherValue) {
                other = otherValue
            }
            multi_option_arr.push(
                <>
                    <Input key={'textBox'} type="text" className="mb-2" id={questionId} disabled={isSignOff['signOffFlag']} value={otherValue === null ? '' : otherValue} onChange={e => this.storeCheckBoxValue(e, 'others', false)} maxLength={25} />
                    {other.length <= 0 && <div className='mandatory-field'>* This field is mandatory.</div>}
                </>
            )
        }

        return multi_option_arr
    }

    setDisableStatus = (questionId) => {
        const { categoryAnswer, subCategoryId } = this.props
        let tempArr = []

        if (categoryAnswer) {
            if (categoryAnswer[subCategoryId]) {
                if (categoryAnswer[subCategoryId][questionId]) {
                    let arr = categoryAnswer[subCategoryId][questionId]
                    if (arr.some(val => val.name === '1')) {
                        let obj = arr.find(o => o.name === '1');
                        let disableOne = {
                            name: obj.value,
                            value: 1
                        }
                        tempArr.push(disableOne)
                    }

                    if (arr.some(val => val.name === '2')) {
                        let obj = arr.find(o => o.name === '2');
                        let disableTwo = {
                            name: obj.value,
                            value: 2
                        }
                        tempArr.push(disableTwo)
                    }

                    if (arr.some(val => val.name === '3')) {
                        let obj = arr.find(o => o.name === '3');
                        let disableThree = {
                            name: obj.value,
                            value: 3
                        }
                        tempArr.push(disableThree)
                    }

                    if (arr.some(val => val.name === '4')) {
                        let obj = arr.find(o => o.name === '4');
                        let disableFour = {
                            name: obj.value,
                            value: 4
                        }
                        tempArr.push(disableFour)
                    }

                    if (arr.some(val => val.name === '5')) {
                        let obj = arr.find(o => o.name === '5');
                        let disableFive = {
                            name: obj.value,
                            value: 5
                        }
                        tempArr.push(disableFive)
                    }
                }
            }
        }
        return tempArr
    }

    multipleButton = (options_arr, questionId) => {
        let multi_button_arr = []
        let tempArr = [null, null, null, null]

        options_arr.map((value, index) => {
            multi_button_arr.push(
                <FormGroup key={(index + 1) * questionId} check className="mb-2">
                    <Row>
                        <Col xs="4" className="text-left align-self-center font-weight-bold">{value['optionName']}
                            <span className="info-tooltip">
                                <FontAwesomeIcon className="text-light-blue ml-1" icon={faInfoCircle} />
                                <div className="tooltip-text">{value['hoverText']}</div>
                            </span>
                        </Col>
                        {this.drawButton(questionId, value, tempArr)}
                    </Row>
                </FormGroup>
            )
            return true
        })
        return multi_button_arr
    }

    multipleButtonWithCheckBox = (options_arr, questionId) => {
        let multiButton_check_arr = []
        let showOther = false
        const { categoryAnswer, subCategoryId, isSignOff } = this.props
        const { showOtherInput, otherFlag, otherValue } = this.state
        let tempArr = [null, null, null, null, null]

        if (categoryAnswer) {
            if (categoryAnswer[subCategoryId]) {
                if (categoryAnswer[subCategoryId][questionId]) {
                    let tempHolder = categoryAnswer[subCategoryId][questionId]
                    if (Object.keys(tempHolder).length === 0) {
                        showOther = true
                        this.disableNxt(true)
                        if (!showOtherInput) {
                            this.setState({ showOtherInput: false })
                        }
                        this.setState({ otherValue: '' })
                    }
                    else if (Object.keys(tempHolder).length === 1 && tempHolder[0]['name'] === '') {
                        showOther = true
                        if (!showOtherInput) {
                            if (!otherFlag) {
                                this.setState({ showOtherInput: true })
                            } else {
                                this.setState({ showOtherInput: false })
                            }
                        } else {
                            this.setState({ showOtherInput: true })
                        }
                        this.setState({ otherValue: tempHolder[0]['value'] })
                        this.disableNxt(false)
                    } else if (!showOtherInput && Object.keys(tempHolder).length < 5) {
                        showOther = false
                        this.disableNxt(true)
                    }
                    else {
                        showOther = false
                        this.disableNxt(false)
                    }
                } else {
                    showOther = true
                    this.disableNxt(true)
                }
            }
        }

        options_arr.map((value, index) => {
            if (value['optionName'] !== 'Other') {
                multiButton_check_arr.push(
                    <FormGroup key={(index + 1) * questionId} check className="mb-2">
                        <Row className="m-0">
                            <Col xs="4" className="text-left align-self-center font-weight-bold pl-0 pr-2">{value['optionName']}</Col>
                            {this.drawButton(questionId, value, tempArr)}
                        </Row>
                    </FormGroup>
                )
            } else {
                multiButton_check_arr.push(
                    <Row key={'other'} className="align-items-center m-0">
                        <Col xs="4" className="pl-4 py-2">
                            <CustomInput className="font-weight-bold" type="checkbox" disabled={!showOther} id={questionId + index} onChange={e => this.otherFunction(e)} checked={showOtherInput} label='Other than above' />
                        </Col>
                        <Col className="px-1">
                            {showOtherInput && <>
                                <Input type="text" id={questionId} disabled={!isSignOff['signOffFlag'] ? !showOther ? true : false : true} value={otherValue} onChange={e => this.storeOther(e)} maxLength={25} />
                                {otherValue.length <= 0 && <div className='mandatory-field'>* This field is mandatory.</div>}</>
                            }
                        </Col>
                    </Row>
                )
            }
            return true
        })

        return multiButton_check_arr
    }

    drawButton = (questionId, value, tempArr) => {
        const { categoryAnswer, subCategoryId, isSignOff } = this.props
        const { showOtherInput } = this.state
        let tempButton = []
        let disableState = []
        let categoryAnswerValue = null
        disableState = this.setDisableStatus(questionId)
        disableState.map((val) => {
            if (val.value === 1) {
                tempArr[0] = val
            }
            if (val.value === 2) {
                tempArr[1] = val
            }
            if (val.value === 3) {
                tempArr[2] = val
            }
            if (val.value === 4) {
                tempArr[3] = val
            }
            if (val.value === 5 && tempArr.length > 4) {
                tempArr[4] = val
            }
            return true
        })

        if (categoryAnswer) {
            if (categoryAnswer[subCategoryId]) {
                if (categoryAnswer[subCategoryId][questionId]) {
                    categoryAnswerValue = categoryAnswer[subCategoryId][questionId]
                }
            }
        }

        if (tempArr.length > 4) {
            tempArr.map((itemName, id) => {
                let numb = id + 1
                tempButton.push(<Col key={id} className="text-right px-2"> <Button id={questionId} outline disabled={!isSignOff['signOffFlag'] ? !showOtherInput ? itemName ? itemName['name'] === value['optionName'] ? false : true : false : true : true} name={value['optionName']} value={numb} block className="rounded-pill rounded-btn-width py-0 px-2" color={categoryAnswerValue ? categoryAnswerValue.some(val => parseInt(val.name) === numb && val.value === value['optionName']) ? 'primary' : 'secondary' : 'secondary'} onClick={e => this.storeValues(e)}>{numb}</Button> </Col>)
                return true
            })
        } else {
            tempArr.map((itemName, id) => {
                let numb = id + 1
                tempButton.push(<Col key={id} className="text-right px-2"> <Button id={questionId} outline name={value['optionName']} disabled={!isSignOff['signOffFlag'] ? itemName ? itemName['name'] === value['optionName'] ? false : true : false : true} value={numb} block className="rounded-pill rounded-btn-width py-0 px-2" color={categoryAnswerValue ? categoryAnswerValue.some(val => parseInt(val.name) === numb && val.value === value['optionName']) ? 'primary' : 'secondary' : 'secondary'} onClick={e => this.storeValues(e)}>{numb}</Button> </Col>)
                return true
            })
        }

        return tempButton
    }

    otherFunction = (e) => {
        let check = e.target.checked
        let id = this.state.questionId

        let tempState = { ...this.state.values }
        if (tempState[id]) {
            if (tempState[id].length > 0) {
                let tempValueArray = [...this.state.values[id]]

                if (tempValueArray.some(elem => elem.name === '')) {
                    for (var i = 0; i < tempValueArray.length; i++) {
                        if (tempValueArray[i].name === '') {
                            tempValueArray.splice(i, 1);
                        }
                    }

                    this.setState(prevState => ({
                        values: {
                            ...prevState.values,
                            [id]: [...tempValueArray]
                        },
                        otherValue: '',
                        showOtherInput: check,
                        otherFlag: true
                    }), () => { this.addToAnswerChip() })
                }
            } else {
                this.setState({ showOtherInput: check, otherFlag: true })
            }
        } else {
            this.setState({ showOtherInput: check, otherFlag: true })
        }
    }

    storeOther = (event) => {
        let val = event.target.value
        let q_id = event.target.id
        let tempValArr = []
        let tempVal = {
            'name': '',
            'value': val
        }

        tempValArr.push(tempVal)
        if (val.length === 0) {
            if (this.state.values[q_id]) {
                let tempValueArray = [...this.state.values[q_id]]
                tempValueArray.splice(0, 1)

                this.setState(prevState => ({
                    values: {
                        ...prevState.values,
                        [q_id]: [...tempValueArray]
                    }
                }), () => { this.addToAnswerChip() })
            }
        } else {
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    [q_id]: tempValArr
                },
                disableNext: true
            }), () => { this.addToAnswerChip() })
        }

        if (this.state.showOtherInput) {
            this.setState({ otherValue: val }, () => { this.addToAnswerChip() })
        }
    }

    storeRadioValue = (e, otherValue) => {
        let val = e.target.value
        const { categoryId, subCategoryId } = this.props
        let q_id = this.state.questionId
        let tempValArr = []
        let tempVal = {}

        if (otherValue) {
            this.setState({ radioTextBox: true })
        } else if (otherValue === false) {
            this.setState({ radioTextBox: false })
        }

        if (otherValue === null) {
            tempVal = {
                'name': this.state.globalRadio,
                'value': val
            }
        } else if (otherValue === true) {
            tempVal = {
                'name': val,
                'value': null
            }
        } else {
            tempVal = {
                'name': '',
                'value': val
            }
            this.setState({ radioOtherValue: '' })
        }

        tempValArr.push(tempVal)

        if (categoryId === 1 && subCategoryId === 1 && q_id === 1) {
            let dinningHall = e.target.value
            this.props.addHallSize({ dinningHall })
        }

        this.setState(prevState => ({
            values: {
                ...prevState.values,
                [q_id]: [...tempValArr]
            },
            disableNext: true,
            radioOtherValue: val
        }), () => { this.addToAnswerChip() })
    }

    storeCheckBoxValue = (e, optionStatus, textBoxStatus) => {
        let val = e.target.value
        let q_id = this.state.questionId
        let name = null
        let tempVal = {}
        let tempValArr = []
        let stateCopy = { ...this.state.values }

        if (optionStatus && e.target.checked) {
            this.setState({ disableOthers: true })
        } else {
            this.setState({ disableOthers: false })
        }

        if (textBoxStatus && e.target.checked) {
            this.setState({ showOtherTextBox: true, globalCheck: val })
            tempVal = {
                name: val,
                value: null
            }
        } else if (textBoxStatus && !e.target.checked) {
            this.setState({ showOtherTextBox: false, otherValue: '', globalCheck: val })
            name = val
        } else {
            tempVal = {
                name: '',
                value: val
            }
        }

        if (e.target.checked) {
            if (optionStatus && stateCopy[q_id]) {
                this.deleteValue(q_id, val)
                tempValArr.push(tempVal)
                this.setState(prevState => ({
                    values: {
                        ...prevState.values,
                        [q_id]: [...tempValArr]
                    },
                    disableNext: true
                }), () => { this.addToAnswerChip() })
            } else {
                if (stateCopy[q_id]) {
                    tempValArr.push(...this.state.values[q_id], tempVal)
                } else {
                    tempValArr.push(tempVal)
                }
                this.setState(prevState => ({
                    values: {
                        ...prevState.values,
                        [q_id]: [...tempValArr]
                    },
                    disableNext: true
                }), () => { this.addToAnswerChip() })
            }
        } else if (this.state.showOtherTextBox && optionStatus === 'others') {
            const { globalCheck } = this.state
            tempVal = {
                'name': globalCheck,
                'value': val
            }
            if (stateCopy[q_id]) {
                if (stateCopy[q_id].some(val => val.name === globalCheck)) {
                    let valueOfOther = _.find(stateCopy[q_id], { 'name': globalCheck })
                    valueOfOther['value'] = val
                    tempValArr.push(...stateCopy[q_id])
                } else {
                    tempValArr.push(...this.state.values[q_id], tempVal)
                }
            } else {
                tempValArr.push(tempVal)
            }
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    [q_id]: [...tempValArr]
                },
                disableNext: true,
                otherValue: val
            }), () => { this.addToAnswerChip() })

        }
        else if (!e.target.checked) {
            this.deleteValue(q_id, val, name)
        }
    }

    deleteValue = (q_id, val, name) => {
        if (this.state.values[q_id]) {
            let tempValueArray = [...this.state.values[q_id]]

            for (var i = 0; i < tempValueArray.length; i++) {
                if (tempValueArray[i].value === val && !name) {
                    tempValueArray.splice(i, 1);
                } else if (tempValueArray[i].name === name) {
                    tempValueArray.splice(i, 1);
                }
            }

            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    [q_id]: [...tempValueArray]
                }
            }), () => { this.addToAnswerChip() })

            if (tempValueArray.length === 0) {
                this.setState({ disableNext: true })
            }
        }
    }

    storeMultiButtonValue = (e) => {
        let val = e.target.value
        let valName = e.target.name
        let q_id = e.target.id
        let name = e.target.name
        let tempValArr = []
        let tempVal = {
            'name': val,
            'value': name
        }

        let tempState = { ...this.state.values }

        if (tempState[q_id]) {
            let tempValueArray = [...this.state.values[q_id]]

            if (tempValueArray.some(elem => elem.value === valName && elem.name === val)) {
                for (let i = 0; i < tempValueArray.length; i++) {
                    if (tempValueArray[i].value === valName) {
                        tempValueArray.splice(i, 1);
                    }
                }
                this.setState(prevState => ({
                    values: {
                        ...prevState.values,
                        [q_id]: [...tempValueArray]
                    }
                }), () => { this.addToAnswerChip() })
            }

            else if (tempValueArray.some(elem => elem.value === valName && elem.name !== val)) {
                for (let j = 0; j < tempValueArray.length; j++) {
                    if (tempValueArray[j].value === valName) {
                        tempValueArray.splice(j, 1);
                    }
                }
                tempValueArray.push(tempVal)
                this.setState(prevState => ({
                    values: {
                        ...prevState.values,
                        [q_id]: [...tempValueArray]
                    }
                }), () => { this.addToAnswerChip() })
            }
            else {
                tempValArr.push(...this.state.values[q_id], tempVal)
                this.setState(prevState => ({
                    values: {
                        ...prevState.values,
                        [q_id]: [...tempValArr]
                    },
                }), () => { this.addToAnswerChip() })
            }

        } else {
            tempValArr.push(tempVal)
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    [q_id]: [...tempValArr]
                },
            }), () => { if (tempValArr) { this.addToAnswerChip() } })
        }
    }

    storeValues = (e) => {
        if (e.target.type === 'radio') {
            this.storeRadioValue(e)
        } else if (e.target.type === 'checkbox') {
            this.storeCheckBoxValue(e)
        } else if (e.target.type === 'button') {
            this.storeMultiButtonValue(e)
        }
    }

    addToAnswerChip = () => {
        this.barResponse()
    }

    storeStateOfCheckMark = (val) => {
        this.setState({
            answeredSubCategories: {
                ...this.props.answerSubCategory,
                [this.props.subCategoryId]: val
            }
        }, () => { this.props.addAnswerSubCategories(this.state.answeredSubCategories) }
        )
    }

    handleCheckMark = () => {
        const { questionsList, subCategoryId, categoryAnswer } = this.props
        let questionsObj = _.find(questionsList, { subCategoryId: subCategoryId })
        const { questions } = questionsObj
        const { nextQuestionObj } = this.state

        if (nextQuestionObj) {
            this.disableNxt(true)
            let questionObj = _.findLast(questions, { subCategoryId: subCategoryId })
            if (nextQuestionObj.questionId !== 0) {
                this.storeStateOfCheckMark(true)
            } else {
                Object.keys(categoryAnswer[subCategoryId]).map((ansId) => {

                    if (parseInt(ansId) === questionObj.questionId) {
                        if (categoryAnswer[subCategoryId][ansId].length > 0) {
                            this.storeStateOfCheckMark(true)
                        } else {
                            this.storeStateOfCheckMark(false)
                        }
                    } else {
                        let tempAnsweredSubCat = { ...this.props.answerSubCategory }
                        tempAnsweredSubCat[subCategoryId] = false
                        this.setState({
                            answeredSubCategories: {
                                ...tempAnsweredSubCat,
                            }
                        }, () => { this.props.addAnswerSubCategories(this.state.answeredSubCategories) })
                    }
                    return true
                })
            }
            this.skipProccessQues()
        } else {
            let checkMark = true
            questions.map((value) => {
                let quesType = value.questionType.questionTypeName
                if (value.isActive && quesType !== 'InputBox') {
                    let answerObj = categoryAnswer[subCategoryId][value.questionId]
                    if (answerObj) {
                        if (answerObj.length === 0) {
                            checkMark = false
                        }
                        if (answerObj.length > 0) {
                            Object.values(answerObj).map((val) => {
                                if (val.value === null || val.value === '') {
                                    checkMark = false
                                }
                                return true
                            })
                        }
                        if (answerObj.length > 0 && checkMark) {
                            checkMark = true
                        }

                    } else {
                        checkMark = false
                    }
                }
                return true
            })
            this.storeStateOfCheckMark(checkMark)
        }
    }

    skipProccessQues = () => {
        const { nextQuestionObj } = this.state
        const { subCategoryId, categoryId, questionsList } = this.props
        // let ques_order = this.state.questionId
        let ques_order = this.state.quesOrder
        let qList = [...questionsList]
        let questionsObj = _.find(qList, { subCategoryId: subCategoryId, categoryId: categoryId })
        const { questions } = questionsObj

        let temp_question_id = parseInt(nextQuestionObj['questionId'])
        if (temp_question_id !== 0) {
            let tempAnswer = { ...this.props.categoryAnswer }
            for (let i = this.state.questionId + 1; i <= nextQuestionObj['questionId'] - 1; i++) {
                let questionIObj = _.find(questions, { subCategoryId: subCategoryId, questionId: i })
                if (questionIObj.isActive) {
                    questionIObj.isActive = false
                }
            }
            this.props.addAnswerChips(tempAnswer)

            if (categoryId !== nextQuestionObj.categoryId) {
                this.setState({ toApp: false, categryId: categoryId, subCatgryId: subCategoryId, quesOrder: ques_order, nextQuestionObj: null })
            } else {
                let tempQObj = _.find(qList, { subCategoryId: nextQuestionObj['subCategoryId'], categoryId: nextQuestionObj['categoryId'] })
                let nextQuestion = _.find(tempQObj['questions'], { questionId: nextQuestionObj['questionId'] })
                ques_order = parseInt(nextQuestion.displayOrder)

                this.setState({ toApp: true, categryId: parseInt(nextQuestionObj.categoryId), subCatgryId: parseInt(nextQuestionObj.subCategoryId), quesOrder: ques_order, nextQuestionObj: null })
            }
        } else {
            // ques_order = this.state.questionId + 1
            ques_order = this.state.quesOrder + 1
            questions.map((value) => {
                if (!value.isActive) {
                    value.isActive = true
                }
                return true
            })
            this.setState({ toApp: true, categryId: parseInt(nextQuestionObj.categoryId), subCatgryId: parseInt(nextQuestionObj.subCategoryId), quesOrder: ques_order, nextQuestionObj: null })
        }
        this.props.addQuestions(qList)
    }

    nextQuestion = () => {
        const { categoryId, subCategoryId, category } = this.props
        const { totalQuestion, questionIndex, nextQuestionObj, showOtherTextBox, radioTextBox } = this.state
        // let ques_order = this.state.questionId
        let ques_order = this.state.quesOrder

        if (showOtherTextBox) {
            this.setState({ showOtherTextBox: false, otherValue: '' })
        }
        if (radioTextBox) {
            this.setState({ radioTextBox: false })
        }

        if (nextQuestionObj) {
            this.skipProccessQues()
        } else {
            let categoryArr = _.find(category, { 'categoryId': categoryId })
            let indexSubCat = _.findIndex(categoryArr['subCategories'], { 'subCategoryId': subCategoryId })

            if (totalQuestion - 1 === questionIndex) {

                if (indexSubCat === categoryArr['subCategories'].length - 1) {
                    let indexCat = _.findIndex(category, { 'categoryId': categoryId })
                    if (indexCat === category.length - 1) {
                        this.setState({ toApp: true, categryId: null, subCatgryId: null, quesOrder: null, values: {} })
                    } else {
                        let tempHolder = category[indexCat + 1]['subCategories'][0]
                        this.setState({ toApp: true, categryId: category[indexCat + 1]['categoryId'], subCatgryId: tempHolder['subCategoryId'], quesOrder: tempHolder['questions'][0]['displayOrder'], values: {} })
                    }
                } else {
                    let checkQuestionexist = _.find(categoryArr['subCategories'][indexSubCat + 1]['questions'], { questionId: this.state.questionId, isActive: true })
                    if (!checkQuestionexist) {
                        // ques_order = categoryArr['subCategories'][indexSubCat + 1]['questions'][0]['questionId']
                        ques_order = categoryArr['subCategories'][indexSubCat + 1]['questions'][0]['displayOrder']
                    } else {
                        ques_order = 1
                    }
                    this.setState({ toApp: true, categryId: categoryId, subCatgryId: categoryArr['subCategories'][indexSubCat + 1]['subCategoryId'], quesOrder: ques_order, values: {} })
                }
            }
            else {
                this.setState({ toApp: true, categryId: categoryId, subCatgryId: subCategoryId, quesOrder: ques_order + 1 })
            }
        }
    }

    barResponse = async () => {
        const { categoryId, subCategoryId, surveyGUID, token } = this.props
        const { quesFact, quesTypeId, values } = this.state
        let ques_order = this.state.questionId

        this.setState(prevState => ({
            chips: {
                ...prevState.chips,
                [this.props.subCategoryId]: { ...this.state.values }
            }
        }), () => {
            this.props.addAnswerChips(this.state.chips)
        })

        let payload = {
            "categoryId": categoryId,
            "subCategoryId": subCategoryId,
            "questionId": ques_order,
            "questionTypeId": quesTypeId,
            "questionFact": quesFact,
            "answers": values[ques_order],
            "surveyId": surveyGUID['surveyId']
        }
        const answerResponse = await TokenValidity.checkTokenValidity(baseurl + '/Question/submitanswer', payload, token)

        if (answerResponse) {
            this.setState(prevState => ({
                bars: {
                    ...prevState.bars,
                    [this.props.subCategoryId]: { ...answerResponse }
                },
                nextQuestionObj: answerResponse['nextQuestion'],
            }), () => { this.props.addRecomPriorBar(this.state.bars) })
        }

        if (answerResponse.hasAdditionalWeights) {
            const payload = {}
            const categoryObject = await TokenValidity.checkTokenValidity(baseurl + '/question/all', payload, token)
            if (categoryObject) {
                let categoryArr = Object.values(categoryObject)[0]
                this.props.addCategories(categoryArr)
            }
        }

        this.handleCheckMark()
    }

    toResult = async (cId) => {
        let payload = {}
        const { token } = this.props
        const answerResponse = await TokenValidity.checkTokenValidity(baseurl + '/Survey/result/survey/category/' + cId, payload, token)
        if (answerResponse) {
            let resultObj = {
                [cId]: answerResponse
            }
            this.props.addCategoryResult(resultObj)
            this.setState({ toApp: false, toResult: true })
        } else {
            this.setState({ toApp: true, toResult: false })
        }
    }

    prevQuestion = () => {
        const { categoryId, subCategoryId, category, questionsList } = this.props
        const { questionIndex } = this.state
        // let ques_order = this.state.questionId
        let ques_order = this.state.quesOrder

        if (questionIndex === 0) {

            let categoryArr = _.find(category, { 'categoryId': categoryId })
            let indexSubCat = _.findIndex(categoryArr['subCategories'], { 'subCategoryId': subCategoryId })

            if (indexSubCat === 0) {
                let indexCat = _.findIndex(category, { 'categoryId': categoryId })
                let subCatLength = null
                if (indexCat !== 0) {
                    subCatLength = category[indexCat - 1]['subCategories'].length
                } else {
                    subCatLength = category[indexCat]['subCategories'].length
                }

                if (indexCat === 0) {
                    this.setState({ toApp: true, categryId: null, subCatgryId: null, quesOrder: null, values: {}, disableQuestion: true })
                } else {
                    this.toResult(category[indexCat - 1]['categoryId'])
                    this.setState({ categryId: category[indexCat - 1]['categoryId'], subCatgryId: category[indexCat - 1]['subCategories'][subCatLength - 1]['subCategoryId'], quesOrder: ques_order - 1, values: {} })
                }

            } else {
                let sid = categoryArr['subCategories'][indexSubCat - 1]['subCategoryId']
                let question_arr = _.find(questionsList, { subCategoryId: sid })
                let lastQuestionObj = _.findLast(question_arr.questions, { isActive: true })
                // ques_order = lastQuestionObj.questionId
                ques_order = lastQuestionObj.displayOrder
                this.setState({ toApp: true, categryId: categoryId, subCatgryId: categoryArr['subCategories'][indexSubCat - 1]['subCategoryId'], quesOrder: ques_order, values: {} })
            }

        } else {
            this.setState({ toApp: true, categryId: categoryId, subCatgryId: subCategoryId, quesOrder: ques_order - 1 })
        }
    }

    renderNext = () => {
        const { questionsList, category, categoryId } = this.props
        const { disableNext, questionId } = this.state
        let categryObj = _.findLast(category, { categoryId: categoryId })
        let subCategryObj = _.findLast(categryObj.subCategories)
        let subCategryQuestion = _.find(questionsList, { subCategoryId: subCategryObj.subCategoryId })
        const { questions } = subCategryQuestion
        let lastQuestionId = _.findLast(questions, { isActive: true })
        if (lastQuestionId) {
            // let id = lastQuestionId.questionId
            let id = lastQuestionId.displayOrder
            let qType = lastQuestionId.questionType.questionTypeName
            this.props.addLastID({ id, qType })

            if (lastQuestionId.questionId === questionId) {
                return (<Button block size="sm" color="primary" className="nxt-btn" disabled={true}>Next</Button>)
            } else {
                return (<Button block size="sm" color="primary" className="nxt-btn" onClick={this.nextQuestion} disabled={disableNext}>Next</Button>)
            }
        } else {
            return (<Button block size="sm" color="primary" className="nxt-btn" onClick={this.nextQuestion} disabled={disableNext}>Next</Button>)
        }
    }

    renderPrevious = () => {
        const { answerSubCategory, categoryId, questionsList, category } = this.props
        let categoryStatus = true
        let disablePrev = false
        let categryObj = _.find(category, { categoryId: categoryId })
        let firstSubCat = categryObj['subCategories'][0]['subCategoryId']
        let subCategryQuestion = _.find(questionsList, { subCategoryId: firstSubCat })
        const { questions } = subCategryQuestion

        if (questions[0]['questionId'] === this.state.questionId) {
            let subCatId = questions[0]['subCategoryId']
            Object.keys(answerSubCategory).map((subActive) => {
                if (parseInt(subActive) < subCatId && answerSubCategory[subActive] && categoryStatus) {
                    categoryStatus = true
                    disablePrev = false
                } else if (parseInt(subActive) < subCatId && !answerSubCategory.subActive) {
                    categoryStatus = false
                    disablePrev = true
                }
                return true
            })
        }

        return (<div><Button color="secondary" block size="sm" className="pre-btn" onClick={this.prevQuestion} disabled={disablePrev}>Previous</Button></div>)
    }

    render() {
        const { questionTxt, toApp, categryId, subCatgryId, quesOrder, disbaleSubmit, disableQuestion, toResult } = this.state
        if (toApp) {
            return <Redirect to={{ pathname: '/dashboard', state: { disable: disableQuestion, categoryId: categryId, subCategoryId: subCatgryId, questionId: quesOrder, disableSubmitButton: disbaleSubmit } }} />
        }
        if (toResult) {
            return <Redirect to={{ pathname: '/categoryResult', state: { categoryId: categryId, subCategoryId: subCatgryId } }} />
        }

        return (
            <div className="shadow h-75 question-block bg-white overflow-auto">
                <div className="p-4 h-100" style={{ maxHeight: '100vh' }}>
                    <div className="d-flex flex-column h-100">
                        <h5 className="h5 text-center font-weight-bold question-heading mb-2">{questionTxt}</h5>
                        <Form onSubmit={e => { e.preventDefault(); }} className="text-left h-100 question-block--height options-form d-flex align-items-center justify-content-center flex-column">
                            <div className="w-100">
                                {this.renderOption()}
                            </div>
                        </Form>
                        <Row>
                            <Col xs="2" className="pr-0">
                                {this.renderPrevious()}
                            </Col>
                            <Col xs="8" className="px-2 my-auto">
                                <Pagination aria-label="question navigation" className="question-navigation w-100" listClassName="justify-content-center text-center mx-auto my-0 flex-wrap">

                                    {this.renderBadge()}
                                </Pagination>
                            </Col>
                            <Col xs="2" className="pl-0">
                                {this.renderNext()}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { questionsList, categoryAnswer, category, token, surveyGUID, answerSubCategory, isSignOff } = state
    return {
        questionsList,
        categoryAnswer,
        category,
        token,
        surveyGUID,
        answerSubCategory,
        isSignOff
    }
}

const Questions = connect(mapStateToProps, mapDispatchToProps)(Question);
export default Questions