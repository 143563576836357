import React, { PureComponent } from "react"
import { Container } from 'reactstrap';
class SplashScreen extends PureComponent { 
  render() {
    return (
      <Container fluid className="splash-screen d-flex h-100 w-100 justify-content-center align-items-center">
    </Container>
    )
  }
}
export default SplashScreen
