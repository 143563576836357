import React, { PureComponent } from "react"
import RenovationTable from "./components/RenovationTable"
import { connect } from 'react-redux'



class RenovationContent extends PureComponent {


    render() {
        return (
            <>
                <div className="shadow h-100 question-block bg-white overflow-auto">
                    <div className="pt-5 px-5 h-100" style={{maxHeight:'100vh'}}>
                        <div className="d-flex flex-column h-100">
                            <h3>Full Course Calculator: <span className="font-weight-bold">For {this.props.destinationCategoryProperty.selectedDestinationCategoryName}</span></h3>
                            <RenovationTable/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return { token: state.token, destinationCategoryProperty: state.destinationCategoryProperty };

  }
  
  export default connect(mapStateToProps)(RenovationContent)