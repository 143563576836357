import {ADD_CATEGORY, ADD_QUESTIONS, ADD_STUDENT_COUNT, ADD_HALL_SIZE, ADD_ANSWER_CHIPS, ADD_TOKEN, LOG_OUT, ADD_CURRENT_ID, 
    ADD_SURVEY_ID, STORE_GENERAL_INFO, ADD_RECOM_PRIOR, SET_DESTINATION_CATEGORIES, SET_DESTINATION_CATEGORY_PROPERTIES, SET_FINAL_BUDGET, ADD_RESULT, ADD_LAST_ID, ADD_SUB_CATEGORY_ID, SET_SURVEY_RESULT, SET_SELECTED_DESTINATION_CATID, ADD_SIGNOFF_FLAG, SET_SURVEY_COMPLETED, UNANSWERED_QUESTION} from '../constants/action_types'

export function addCategories(payload) {
    return { type: ADD_CATEGORY, payload }
};

export function addQuestions(payload) {
    return { type: ADD_QUESTIONS, payload }
}

export function addStudentCount(payload) {
    return { type: ADD_STUDENT_COUNT, payload }
}

export function addHallSize(payload) {
    return { type: ADD_HALL_SIZE, payload }
}

export function addAnswerChips(payload) {
    return { type: ADD_ANSWER_CHIPS, payload }
}

export function addToken(payload) {
    return { type: ADD_TOKEN, payload }
}

export function addCurrentId(payload) {
    return { type: ADD_CURRENT_ID, payload }
}

export function addSurveyId(payload) {
    return { type: ADD_SURVEY_ID, payload }
}

export function addGeneralAccountInfo(payload) {
    return { type: STORE_GENERAL_INFO, payload }
}

export function addRecomPriorBar(payload) {
    return { type: ADD_RECOM_PRIOR, payload }
}

export function addCategoryResult(payload) {
    return { type: ADD_RESULT, payload }
}

export function logOut(payload) {
    return { type: LOG_OUT, payload }
}

export function setDestinationCategories(payload) {
    return { type: SET_DESTINATION_CATEGORIES, payload }
}
export function setDestinationCategoryProperties(payload) {
    return { type: SET_DESTINATION_CATEGORY_PROPERTIES, payload }
}
export function setFinalBudget(payload) {
    return { type: SET_FINAL_BUDGET, payload }
}
export function addLastID(payload) {
    return { type: ADD_LAST_ID, payload }
}

export function addAnswerSubCategories(payload) {
    return { type: ADD_SUB_CATEGORY_ID, payload }
}
export function setSurveyResult(payload) {
    return { type: SET_SURVEY_RESULT, payload }
}

export function setSignOffFlag(payload) {
    return { type: ADD_SIGNOFF_FLAG, payload }
}
export function setSelectedDestinationCategoryId(payload) {
    return { type: SET_SELECTED_DESTINATION_CATID, payload }
}
export function setSurveyCompleted(payload) {
    return { type: SET_SURVEY_COMPLETED, payload }
}
export function setUnansweredQuestion(payload) {
    return { type: UNANSWERED_QUESTION, payload }
}
