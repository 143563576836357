import React, { PureComponent } from "react"
import AnswersBreadcrumb from "./Breadcrumb";
import AnswersChips from "./Chips";

class Answers extends PureComponent {

  render() {
    const { subCategoryId, categoryId } = this.props
    return (
        <div className="shadow border answer-block bg-lightblue p-2">
          <AnswersBreadcrumb subCategoryId={subCategoryId} categoryId={categoryId} />
          <AnswersChips subCategoryId={subCategoryId} />
        </div>
    )
  }
}
export default Answers
