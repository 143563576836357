import React, { PureComponent } from "react"
import LoginLogo from "../../assets/images/login-logo.svg"
import {
  Container, Row, Col, Form,
  FormGroup, Label, Input, CustomInput,
  Button
} from 'reactstrap';
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { setSurveyCompleted, addStudentCount, addToken, addGeneralAccountInfo, setSelectedDestinationCategoryId, addAnswerChips, addRecomPriorBar, addCurrentId, addAnswerSubCategories, setSignOffFlag } from '../../actions/index'
import TokenValidity from '../../helper/TokenValidity'
import { baseurl } from '../../constants/declaration'
import Cookies from 'universal-cookie';
import Splash from '../Splash/'
import jwt from 'jsonwebtoken'

function mapDispatchToProps(dispatch) {
  return {
    addStudentCount: studentCount => dispatch(addStudentCount(studentCount)),
    addToken: token => dispatch(addToken(token)),
    addGeneralAccountInfo: gnrlAccountInfo => dispatch(addGeneralAccountInfo(gnrlAccountInfo)),
    addAnswerChips: categoryAnswer => dispatch(addAnswerChips(categoryAnswer)),
    addRecomPriorBar: recomPriorBar => dispatch(addRecomPriorBar(recomPriorBar)),
    addCurrentId: currentId => dispatch(addCurrentId(currentId)),
    addAnswerSubCategories: answerSubCategory => dispatch(addAnswerSubCategories(answerSubCategory)),
    setSignOffFlag: isSignOff => dispatch(setSignOffFlag(isSignOff)),
    setSelectedDestinationCategoryId: selectedDestinationCategoryId => dispatch(setSelectedDestinationCategoryId(selectedDestinationCategoryId)),
    setSurveyCompleted: isSurveyComplete => dispatch(setSurveyCompleted(isSurveyComplete))
  }
}

class LoginComp extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      toAccountInfo: false,
      username: '',
      password: '',
      showError: false,
      errorMessage: '',
      rememberMeChecked: false,
      loading: true
    }
  }

  componentDidMount() {

    if (this.props.token) {
      const { exp } = jwt.decode(this.props.token['token'])

      if (exp < Date.now() / 1000) {
        this.setState({ toAccountInfo: false })
      } else {
        this.setState({ toAccountInfo: true })
      }
    }

    this.props.setSurveyCompleted({ isSurveyComplete: false })

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 3000)

    const cookies = new Cookies();
    let userInfo = cookies.get('rememberMe');
    if (userInfo) {
      this.setState({ username: userInfo.userName, password: userInfo.password, rememberMeChecked: true })
    }
  }

  validateUser = async () => {
    const { username, password } = this.state
    let payload = {
      'userName': username,
      'password': password,
      'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    let configToken = { 'token': null }

    const loginResult = await TokenValidity.checkTokenValidity(baseurl + '/Customer/login', payload, configToken)
    const { isSucceed, token, generalAccountInfo, signOff, message, destinationCategoryId } = loginResult
    this.props.addAnswerChips([])
    this.props.addAnswerSubCategories({})
    this.props.addRecomPriorBar([])
    this.props.addCurrentId({ currntCategoryId: 1, currntSubCategoryId: 0 })
    if (destinationCategoryId) {
      this.props.setSelectedDestinationCategoryId({ selectedDestinationCategoryId: destinationCategoryId, openBudget: true });
    } else {
      this.props.setSelectedDestinationCategoryId({ selectedDestinationCategoryId: destinationCategoryId, openBudget: false });
    }

    if (isSucceed) {
      this.props.addToken({ token })
      this.props.addGeneralAccountInfo(generalAccountInfo)
      this.props.setSignOffFlag({ signOffFlag: signOff.isVirtuallySignedOff, signOffBy: signOff.signOffBy, signOffDate: signOff.signOffDate })
      this.setState({ toAccountInfo: true, showError: false })
    } else {
      this.setState({ toAccountInfo: false, showError: true, username: '', password: '', errorMessage: message })
      this.props.addToken({ token: '' })
      this.props.setSignOffFlag({ signOffFlag: false })
    }
  }
  onPressed(e) {
    if (e.keyCode === 13) {
      this.validateUser();
    }
  }

  rememberMe = () => {
    const cookies = new Cookies();
    if (!this.state.rememberMeChecked) {
      const payload = {
        userName: this.state.username,
        password: this.state.password
      }
      this.setState({ rememberMeChecked: true })
      cookies.set('rememberMe', payload, { path: '/' });
    } else {
      this.setState({ rememberMeChecked: false })
      cookies.remove('rememberMe');
    }

  }

  renderRememberMe = () => {
    const { username, password, rememberMeChecked } = this.state
    let disableRememberMe = true

    if (username !== '' && password !== '') {
      disableRememberMe = false
    }

    return (
      <CustomInput id="remmember" label=" Remember me" inline type="checkbox" disabled={disableRememberMe} checked={rememberMeChecked} onChange={this.rememberMe} />

    )
  }

  render() {
    const { toAccountInfo, showError, username, password, errorMessage, loading } = this.state

    if (toAccountInfo) {
      return <Redirect to={{ pathname: '/dashboard', state: { disable: true, categoryId: null, subCategoryId: null, questionId: null, disableSubmitButton: true, recom: [], prior: [] } }} />
    }

    return (
      <Container fluid className="h-100 w-100  container-fluid p-0 m-0">
        {loading && <Splash />}
        {!loading && <>
          <Row className="h-100 w-100 p-0 m-0 login-page-animation">
            <Col className="login-form">

            </Col>
            <Col sm="5" className="bg-white d-flex align-items-center" style={{ minWidth: '400px' }}>
              <Form className="form text-center pr-5 mx-auto" style={{ width: "500px" }}>
                <Row>
                  <Col xs="12">
                    <img src={LoginLogo} width="200" className="mx-auto mb-4" alt="" />
                  </Col>
                  <Col xs="12">
                    <FormGroup>
                      <Label for="userName" className="sr-only">Username</Label>
                      <Input
                        type="text"
                        name="userName"
                        id="userName"
                        placeholder="Username"
                        bsSize="lg"
                        onKeyDown={e => this.onPressed(e)}
                        value={username}
                        autoComplete="off"
                        onChange={(e) => this.setState({ 'username': e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup>
                      <Label for="password" className="sr-only">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        bsSize="lg"
                        onKeyDown={e => this.onPressed(e)}
                        value={password}
                        autoComplete="off"
                        onChange={(e) => this.setState({ 'password': e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" className="text-left">
                    {this.renderRememberMe()}
                  </Col>
                  <Col xs="12" className="mt-1">
                    {showError && <Label for="error" className="error error-message-login p-1">{errorMessage}</Label>}
                  </Col>
                  <Col xs="12">
                    <Button color="primary" className="px-5 py-1" onClick={this.validateUser}>Login</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>}
      </Container>
    )
  }
}

function mapStateToProps(state) {
  const { token } = state
  return { token }
}

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComp)
export default Login
